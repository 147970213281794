import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { DialogService } from './../../services/shared/dialog/dialog.service';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher, MatNativeDateModule, MAT_DATE_LOCALE,
// MAT_TOOLTIP_DEFAULT_OPTIONS,
// MAT_SELECT_SCROLL_STRATEGY,
MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import {
// MatTooltipModule,
MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MyGestureConfig } from '../my-gesture-config/my-gesture-config';
import { MatChipsModule } from '@angular/material/chips';
import {
  // customTooltipDefaults,
  // scrollFactory,
  MY_DATES_FORMATS
} from './default-options';
// import { Overlay } from '@angular/cdk/overlay';
import {
  MatMomentDateModule,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  // MAT_MOMENT_DATE_FORMATS
} from '@angular/material-moment-adapter';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { getEsPaginatorIntl } from './paginator-translation/es-paginator-intl';
import 'hammerjs';
@NgModule({
  declarations: [],
  imports: [
    // CommonModule
    /* MatSidenavModule,
        MatSelectModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatCardModule,
        MatExpansionModule,
        MatDialogModule,
        MatDividerModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatStepperModule,
        MatRadioModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatTooltipModule,
        MatSlideToggleModule,
        MatBadgeModule */
  ],
  exports: [
    CommonModule,
    MatSidenavModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatExpansionModule,
    MatDialogModule,
    MatDividerModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatStepperModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    // MatTooltipModule,
    MatSlideToggleModule,
    MatBadgeModule,
    MatMomentDateModule,
    MatTabsModule,
    MatChipsModule
  ],
  providers: [
    DialogService,
    { provide: MatDialogRef, useValue: {} },
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATES_FORMATS },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true }
    },
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'primary' }
    },
    // { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: customTooltipDefaults },
    /* {
      provide: MAT_SELECT_SCROLL_STRATEGY,
      useFactory: scrollFactory,
      deps: [Overlay]
    }, */
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyGestureConfig
    },
    { provide: MatPaginatorIntl, useValue: getEsPaginatorIntl() }
  ]
})
export class AngularMaterialModule {}
