import { ValidationResult } from './../interfaces/validationResult.interface';
import { MyErrorStateMatcher } from './../shared/my-error-state-matcher/my-error-state-matcher.directive';
import { Component, OnInit, Inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import Swal from 'sweetalert2';
import { EmailHandlerService } from '../services/email-handler/email-handler.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { UserService } from '../services/user/user.service';
import { RouterUtilsService } from '../services/router-utils/router-utils.service';
import { SweetalertService } from '../services/shared/sweetalert/sweetalert.service';
import { DevicesDetectorService } from '../services/shared/devices-detector/devices-detector.service';
import { ErrorHandlerService } from '../services/shared/error-handler/error-handler.service';
import { UserType } from '../shared/enums/enums.enum';
import { AngularFireAnalytics } from '@angular/fire/analytics';

declare var heap: any;
@Component({
  selector: 'app-email-handler',
  templateUrl: './email-handler.component.html',
  styleUrls: ['./email-handler.component.scss']
})
export class EmailHandlerComponent implements OnInit {
  urlParams = new URLSearchParams(window.location.search);
  actionHandlerParams: {
    mode: string;
    actionCode: string;
    continueUrl?: string;
    lang?: string | 'en';
  };
  viewMessage: string;
  baseUrl: string;

  resetPasswordForm: FormGroup;
  isModeResetPassword = false;

  hide = true;
  matcher = new MyErrorStateMatcher();
  constructor(
    private devicesService: DevicesDetectorService,
    private router: Router,
    private handlerService: EmailHandlerService,
    private userService: UserService,
    private routerUtils: RouterUtilsService,
    private fb: FormBuilder,
    private swalService: SweetalertService,
    private errorService: ErrorHandlerService,
    private fireAuth: AngularFireAuth,
    private fireAnalitycs: AngularFireAnalytics,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.resetPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      newPassword: [
        '',
        [
          Validators.required,
          Validators.minLength(6),
          this.passwordCustomValidations
        ]
      ]
    });

    // Lowercasing user email
    this.resetPasswordForm.get('email').valueChanges.subscribe((email:string) => {
      this.resetPasswordForm.get('email').patchValue(email.toLowerCase(), { emitEvent: false });
    });

    this.actionHandlerParams = {
      // Get the action to complete: resetPassword | recoverEmail | verifyEmail | signIn
      mode: this.urlParams.get('mode'),
      // Get the one-time code from the query parameter.
      actionCode: this.urlParams.get('oobCode'),
      // (Optional) Get the continue URL from the query parameter if available.
      continueUrl: this.urlParams.get('continueUrl') || undefined,
      // (Optional) Get the language code if available.
      lang: this.urlParams.get('lang') || 'en'
    };
  }

  get newPassword() {
    return this.resetPasswordForm.get('newPassword');
  }
  get formEmail() {
    return this.resetPasswordForm.get('email');
  }

  ngOnInit() {
    this.baseUrl = this.document.location.origin;
    this.handlerService.actionHandlerParams = this.actionHandlerParams;

    if (
      !this.actionHandlerParams.actionCode ||
      !this.actionHandlerParams.mode
    ) {
      this.viewMessage =
        '¡Acá no hay nada que ver! Te redireccionaremos de vuelta a la plataforma..';
      setTimeout(() => {
        this.router.navigate(['']).then(() => window.Intercom('update'));
      }, 4000);
    }

    switch (this.actionHandlerParams.mode) {
      case 'verifyEmail':
        this.handleVerifyEmail();
        break;
      case 'resetPassword':
        this.handleResetPassword();
        break;
      case 'signIn':
        this.handleSignInWithLink();
        break;
      default:
        this.viewMessage =
          '¡Acá no hay nada que ver! Te redireccionaremos de vuelta a la plataforma..';
        setTimeout(() => {
          this.router.navigate(['']).then(() => window.Intercom('update'));
        }, 4000);
        this.errorService.recordError(new Error('Mode not recognized: <mode>=' + this.actionHandlerParams.mode),
        'email-handler.component.ts', 'ngOnInit()', 'Error en switch, acción no válida');
        break;
    }
  }

  handleVerifyEmail(): void {
    this.viewMessage = 'Verificando correo electrónico..';
    // simplemente el applyActionCode(actionCode)
    this.handlerService
      .handleVerifyEmail(
        this.actionHandlerParams.actionCode,
        this.actionHandlerParams.lang
      )
      .then(async () => {
        this.viewMessage = '¡Email verificado!';
        this.showToastVerification();
        heap.track('emailVerification');
        this.fireAnalitycs.logEvent(`emailVerification`);
        setTimeout(() => {
          window.close();
        }, 4000);
      })
      .catch((err) => {
        this.errorService.recordError(err, 'email-handler.component.ts',
        'this.handlerService.handleVerifyEmail()', 'Error al verificar email');
        this.viewMessage = 'Error en verificación de email';
        this.swalInvalidCode();
        setTimeout(() => {
          this.router.navigate(['']).then(() => window.Intercom('update'));
        }, 5000);
      });
  }

  handleResetPassword(): void {
    this.viewMessage = 'Restablecer contraseña..';
    this.insertResetPasswordForm();

    // verifica que el action code es valid
    this.fireAuth.auth
      .verifyPasswordResetCode(this.actionHandlerParams.actionCode)
      .then((email) => {
        // insert email and disable
        this.formEmail.patchValue(email);
        this.formEmail.disable();
        this.newPassword.patchValue('');
        heap.track('resetPassword', {action: 'verification'});
        this.fireAnalitycs.logEvent(`resetPassword`, {action: 'verification'});
      })
      .catch((error) => {
        this.errorService.recordError(error, 'email-handler.component.ts',
        'this.handlerService.auth.verifyPasswordResetCode()', 'Error al verificar código de reset password');
        let msg = "Error al restablecer contraseña. ";
        msg += this.errorService.getErrorMessage(error.code);
        this.swalPasswordResetError(msg);
      });
  }

  async handleSignInWithLink() {
    let email = window.localStorage.getItem('emailForSignIn');
    if (!email) {
      // User opened the link on a different device. To prevent session fixation
      // attacks, ask the user to provide the associated email again. For example:
      email = window.prompt('Ingresa el email en el que recibiste este enlace');
    }
    await this.userService.loginWithLink(email);
    this.swalSignInSuccess();
    heap.track('signInWithLink');
    this.fireAnalitycs.logEvent(`signInWithLink`);
    setTimeout(() => {
      this.router.navigate(['']).then(() => window.Intercom('update'));
    }, 5000);
  }

  onResetPasswordSubmit(): void {
    this.fireAuth.auth
      .confirmPasswordReset(
        this.actionHandlerParams.actionCode,
        this.newPassword.value
      )
      .then(() => {
        // sign in. El mismo que está en el navbar del landing
        this.userService
          .signInWithEmail({
            email: this.formEmail.value,
            password: this.newPassword.value
          })
          .then((user) => {
            this.swalPasswordResetSuccess();
            heap.track('resetPassword', {action: 'confirmation'});
            this.fireAnalitycs.logEvent(`resetPassword`, {action: 'confirmation'});
            setTimeout(() => {
              if (user.userType === UserType.admin) {
                this.router
                  .navigate(
                    [
                      this.actionHandlerParams.continueUrl.replace(
                        this.baseUrl,
                        ''
                      ) + '/resumen'
                    ],
                    {
                      queryParams: {
                        isDesktop: this.devicesService.isDesktopVar
                      }
                    }
                  )
                  .then(() => window.Intercom('update'))
                  .then(() => window.Intercom('update'));
              } else {
                this.router
                  .navigate(
                    [
                      this.actionHandlerParams.continueUrl.replace(
                        this.baseUrl,
                        ''
                      )
                    ],
                    {
                      queryParams: {
                        isDesktop: this.devicesService.isDesktopVar
                      }
                    }
                  )
                  .then(() => window.Intercom('update'))
                  .then(() => window.Intercom('update'));
              }
            }, 5000);
          })
          .catch((err) => {
            this.errorService.recordError(err, 'email-handler.component.ts',
            'this.userService.signInWithEmail()', 'Error al iniciar sesión');
            this.swalPasswordResetError(err);
          });
      })
      .catch((error) => {
        this.errorService.recordError(error, 'email-handler.component.ts',
        'this.handlerService.auth.confirmPasswordReset()', 'Error al resetear contraseña');
        let msg = "Error al resetear contraseña. ";
        msg += this.errorService.getErrorMessage(error.code);
        this.swalPasswordResetError(msg);
      });
  }

  private insertResetPasswordForm(): void {
    // toggle de variable que muestra el form
    this.isModeResetPassword = true;
  }

  showToastVerification(): void {
    let timerInterval;
    Swal.fire({
      title: `Tu email ha sido verificado exitosamente.`,
      html:
        'Estás listo para realizar tus pagos de arriendo en <span class="neat-text-bold">neat</span>. ' +
        'Esta ventana se cerrara en <strong></strong> segundos, vuelve a la ventana donde te registraste...',
      type: 'success',
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      timer: 5000,
      onBeforeOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          Swal.getContent().querySelector('strong').textContent = (
            Swal.getTimerLeft() / 1000
          ).toFixed(0);
        }, 100);
      },
      onClose: () => {
        clearInterval(timerInterval);
      }
    });
  }

  swalInvalidCode(): void {
    this.swalService.swalWarning(
      `Enlace inválido o expirado.`,
      'Por favor inicia sesión con tu email y contraseña y te enviaremos nuevamente un enlace de verificación'
    );
  }

  swalSignInSuccess(): void {
    this.swalService.swalSuccess(
      `Solicitud de inicio de sesión existoso`,
      'Te redireccionaremos a la plataforma en 5 segundos...'
    );
  }

  swalPasswordResetSuccess(): void {
    this.swalService.swalSuccess(
      `Tu contraseña se ha restablecido correctamente.`,
      'Te redireccionaremos a la plataforma en 5 segundos...'
    );
  }

  swalPasswordResetError(msg: string): void {
    this.swalService.swalError(
      `Ha ocurrido un error en el restablecimiento de tu contraseña.`,
      msg
    );
  }
  passwordCustomValidations(control: FormControl): ValidationResult {
    const hasNumber = /\d/.test(control.value);
    const hasUpper = /[A-Z]/.test(control.value);
    const hasLower = /[a-z]/.test(control.value);
    const valid = hasNumber && hasUpper && hasLower;
    if (!valid) {
      // return what´s not valid
      return { strongPassword: true };
    }
    return null;
  }
}
