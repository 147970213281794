import { Deserializable } from '../../interfaces/deserializable.interface';
import { IReferredInfo } from '../../interfaces/referrals.interface';
import { IPaymentMethod } from '../../interfaces/payment-method.interface';
import { UserType, AuthProvider } from '../../shared/enums/enums.enum';
import { AppGlobals } from '../../shared/constants';

export class User implements Deserializable {
  // Firestore
  public id: string;
  public email: string;
  public displayName: string;
  public phoneNumber?: string;
  public firstNames: string;
  public lastNames: string;
  public userType: UserType | string;
  public birthDate: { day: number, month: number, year: number };
  public rut: string;
  public gender?: string;
  public isInvitedByAdmin: boolean;
  public acceptTerms: boolean;
  public createDate: number;
  public comesFrom: string;
  public paymentMethods: Array<IPaymentMethod>;
  public pendingPaymentMethod: Array<any>;
  public category: string;
  public continuousMonthsPaid: number;
  // define interface
  public extraData: any;
  // Referrals
  public referrerId: string;
  public referredRegistered: Array<IReferredInfo>;
  public referredWhoPayed: Array<IReferredInfo>;

  // Auth Data
  public emailVerified: boolean;
  public lastSignInTime: string;
  public creationTime: string;
  public photoURL: any;
  public isNew: boolean;
  public pinActive?: boolean;

  constructor(init?: Partial<User>) {
    return Object.assign(this, init);
  }

  deserialize(input: any): this {
    return Object.assign(this, input);
  }

  from_authProvider(authProvider: AuthProvider, userCredential: firebase.auth.UserCredential, registerForm: any): this {
    if (authProvider === AuthProvider.firebase) {
      this.firstNames = registerForm.name;
      this.lastNames = registerForm.lastNames;
      this.displayName = registerForm.name + ' ' + registerForm.lastNames;
    } else if (authProvider === AuthProvider.google) {
      const { firstNames, lastNames } = AppGlobals.guessFirstAndLastNamesFrom(userCredential.user.displayName);
      this.firstNames = firstNames;
      this.lastNames = lastNames;
      this.displayName = userCredential.user.displayName;
    }
    this.referredRegistered = [];
    this.referredWhoPayed = [];
    this.id = userCredential.user.uid;
    this.userType = UserType.lessee;
    // this.messagingToken = null;
    this.isInvitedByAdmin = false;
    this.email = userCredential.user.email;
    if (registerForm) {
      this.rut = registerForm.rut;
      this.birthDate = registerForm.birthDate;
      this.referrerId = registerForm.referrerId;
      this.comesFrom = registerForm.comesFrom;
      this.acceptTerms = registerForm.acceptTerms;
    }
    return this;
  }

  extend_with(authData: firebase.User, additionalUserInfo?: firebase.auth.AdditionalUserInfo): this {
    this.lastSignInTime = authData.metadata.lastSignInTime;
    this.creationTime = authData.metadata.creationTime;
    this.emailVerified = authData.emailVerified;
    this.photoURL = authData.photoURL;
    if (additionalUserInfo) {
      this.isNew = additionalUserInfo.isNewUser;
    }
    return this;
  }

  verifyPersonalDataPresence(): boolean {
    if (!this.firstNames || !this.lastNames || !this.rut) {
      return false;
    } else {
      return true;
    }
  }

  showFullName(): string {
    if (this.firstNames && this.lastNames) {
      return this.firstNames + ' ' + this.lastNames;
    } else if (this.firstNames && !this.lastNames) {
      return this.firstNames;
    } else if (!this.firstNames && this.lastNames) {
      return this.lastNames;
    } else if (!(this.firstNames || this.lastNames) && this.displayName) {
      return this.displayName;
    } else {
      return '';
    }
  }
}
