import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
declare var Intercom: any;
@Injectable({
  providedIn: 'root'
})
export class SweetalertService {
  constructor(
    public router: Router,
    ) {}

  swalInfoTimer(msgTitle: string, msgText: string): void {
    let timerInterval;
    Swal.fire({
      title: `${msgTitle}`,
      html: `${msgText} en <strong></strong> segundos.`,
      type: 'info',
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      timer: 5000,
      onBeforeOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          Swal.getContent().querySelector('strong').textContent = (
            Swal.getTimerLeft() / 1000
          ).toFixed(0);
        }, 100);
      },
      onClose: () => {
        clearInterval(timerInterval);
      }
    });
  }

  swalGeneral(
    msgTitle: string,
    msgText: string,
    swalType: any,
    swalButtonText: string,
    swalAllowOutsideClick: boolean = true,
    swalAllowEscapeKey: boolean = true
  ): void {
    Swal.fire({
      title: `${msgTitle}`,
      html: `${msgText}`,
      type: swalType,
      confirmButtonText: swalButtonText,
      customClass: {
        confirmButton: 'mat-raised-button mat-button-base mat-primary'
      },
      buttonsStyling: false,
      allowOutsideClick: swalAllowOutsideClick,
      allowEscapeKey: swalAllowEscapeKey
    });
  }

  swalConfirmationQuestion(
    title: string,
    message: string,
    alertType: any = 'warning',
    confirmBtnText?: any
  ) {
    return Swal.fire({
      title: `${title}`,
      text: `${message}`,
      type: alertType,
      showCancelButton: true,
      /* confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33', */
      customClass: {
        confirmButton: 'mat-raised-button mat-button-base mat-primary mx-3',
        cancelButton: 'mat-raised-button mat-button-base mat-warn mx-3'
      },
      buttonsStyling: false,
      confirmButtonText: confirmBtnText ? confirmBtnText : 'Continuar',
      cancelButtonText: 'Cancelar'
    });
  }

  swalToastGeneral(
    msgTitle: string,
    swalType: any,
    swalPosition: any = 'top-end',
    swalTimer: number = 3000
  ) {
    const Toast = Swal.mixin({
      toast: true,
      position: swalPosition,
      showConfirmButton: false,
      timer: swalTimer
    });

    Toast.fire({
      type: swalType,
      title: `${msgTitle}`
    });
  }
  swalSuccess(msgTitle: string, msgText: string): void {
    Swal.fire({
      title: `${msgTitle}`,
      html: `${msgText}`,
      type: 'success',
      confirmButtonText: 'Aceptar',
      customClass: {
        confirmButton: 'mat-raised-button mat-button-base mat-primary'
      },
      buttonsStyling: false
      /* allowOutsideClick: false,
      allowEscapeKey: false */
    });
  }
  swalSuccessNeat(msgTitle: string, msgText: string): void {
    Swal.fire({
      title: `${msgTitle}`,
      html: `${msgText}`,
      imageUrl: 'https://i.imgur.com/G74elQH.png',
      imageWidth: 190,
      imageHeight: 85,
      imageAlt: 'neat',
      confirmButtonText: 'Aceptar',
      customClass: {
        confirmButton: 'mat-raised-button mat-button-base mat-primary'
      },
      buttonsStyling: false
      /* allowOutsideClick: false,
      allowEscapeKey: false */
    });
  }
  swalIsabelWelcome(msgTitle: string, msgText: string): void {
    Swal.fire({
      title: `${msgTitle}`,
      html: `${msgText}`,
      imageUrl: 'https://i.imgur.com/o86zNFM.png',
      imageWidth: 140,
      imageHeight: 180,
      imageAlt: 'isabel',
      confirmButtonText: 'Aceptar',
      customClass: {
        confirmButton: 'mat-raised-button mat-button-base mat-primary'
      },
      buttonsStyling: false
      /* allowOutsideClick: false,
      allowEscapeKey: false */
    });
  }
  swalInfo(msgTitle: string, msgText: string): void {
    Swal.fire({
      title: `${msgTitle}`,
      html: `${msgText}`,
      type: 'info',
      confirmButtonText: 'Aceptar',
      customClass: {
        confirmButton: 'mat-raised-button mat-button-base mat-primary'
      },
      buttonsStyling: false
      /* allowOutsideClick: false,
      allowEscapeKey: false */
    });
  }

  swalFidelityInfo(msgTitle: string, msgText: string): void {
    Swal.fire({
      title: `${msgTitle}`,
      html: `${msgText}`,
      confirmButtonText: 'Cerrar',
      customClass: {
        confirmButton: 'mat-raised-button mat-button-base mat-primary'
      },
      buttonsStyling: false
    });
  }

  swalError(msgTitle: string, msgText: string): void {
    Swal.fire({
      title: `${msgTitle}`,
      html: `${msgText}`,
      type: 'error',
      confirmButtonText: 'Aceptar',
      customClass: {
        confirmButton: 'mat-raised-button mat-button-base mat-warn'
      },
      buttonsStyling: false
      /* allowOutsideClick: false,
      allowEscapeKey: false */
    });
  }

  swalWarning(msgTitle: string, msgText: string): void {
    Swal.fire({
      title: `${msgTitle}`,
      html: `${msgText}`,
      type: 'warning',
      confirmButtonText: 'Aceptar',
      customClass: {
        confirmButton: 'mat-raised-button mat-button-base mat-warn'
      },
      buttonsStyling: false
      /* allowOutsideClick: false,
      allowEscapeKey: false */
    });
  }
  swalErrorNeat(msgTitle: string, msgText: string): void {
    Swal.fire({
      title: `${msgTitle}`,
      html: `${msgText}`,
      imageUrl: 'https://i.imgur.com/G74elQH.png',
      imageWidth: 190,
      imageHeight: 85,
      imageAlt: 'neat',
      confirmButtonText: 'Aceptar',
      customClass: {
        confirmButton: 'mat-raised-button mat-button-base mat-warn'
      },
      buttonsStyling: false
    });
  }

  /* Swall for components */
  swalRegisterInfo(): void {
    let timerInterval;
    Swal.fire({
      title: `Te hemos enviado un correo para verificar tu usuario.`,
      html:
        'Por favor ingresa a tu email y sigue las instrucciones.Te enviaremos al dashboard en <strong></strong> segundos.',
      type: 'info',
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      timer: 5000,
      onBeforeOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          Swal.getContent().querySelector('strong').textContent = (
            Swal.getTimerLeft() / 1000
          ).toFixed(0);
        }, 100);
      },
      onClose: () => {
        clearInterval(timerInterval);
      }
    });
  }

  swalPromotionAdded(msgTitle: string, msgText: string): void {
    Swal.fire({
      title: `${msgTitle}`,
      html: `${msgText}`,
      type: 'success',
      confirmButtonText: 'Ir a pagar',
      customClass: {
        confirmButton: 'mat-raised-button mat-button-base mat-primary rounded-btn'
      },
      buttonsStyling: false,
      showCloseButton: true,
      /* allowOutsideClick: false,
      allowEscapeKey: false */
    }).then(isConfirm => {
      if (isConfirm) {
        this.router.navigate(['/dashboard/']);
      }
    });
  }

  swalRecoverPin(): void {
    Swal.fire({
      title: `Para esta operación es necesario que hables con el equipo de Neat`,
      html: `Te pediremos información para validar tu identidad. `,
      type: 'info',
      confirmButtonText: 'Háblanos por el chat',
      customClass: {
        confirmButton: 'mat-raised-button mat-button-base mat-primary custom-btn'
      },
      buttonsStyling: false
      /* allowOutsideClick: false,
      allowEscapeKey: false */
    }).then(isConfirm => {
      if (isConfirm) {
        Intercom('showNewMessage', '');
      }
    });;
  }

  successNeatModal( msgText: string, btnText: string): void {
    Swal.fire({
      html: `${msgText}`,
      imageUrl: 'assets/img/icons/success-icon-modal.svg',
      imageWidth: 190,
      imageHeight: 85,
      imageAlt: 'neat',
      confirmButtonText: `${btnText}`,
      customClass: {
        confirmButton: 'mat-raised-button mat-button-base custom-btn-secundary'
      },
      buttonsStyling: false
    });
  }

  
  errorNeatModal( msgText: string, btnText: string): void {
    Swal.fire({
      html: `${msgText}`,
      imageUrl: 'assets/img/icons/error-icon-modal.svg',
      imageWidth: 190,
      imageHeight: 85,
      imageAlt: 'neat',
      confirmButtonText: `${btnText}`,
      customClass: {
        confirmButton: 'mat-raised-button mat-button-base custom-btn-secundary'
      },
      buttonsStyling: false
    });
  }

}
