import { NotAuthenticatedComponent } from './shared/not-authenticated/not-authenticated.component';
import { EmailHandlerComponent } from './email-handler/email-handler.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { NoPageFoundComponent } from './shared/no-page-found/no-page-found.component';

const appRoutes: Routes = [
    {
        path: '',
        loadChildren: () =>
            import('./landing/landing.module').then(m => m.LandingModule)
    },
    {
        path: 'dashboard',
        loadChildren: () =>
            import('./dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
        path: 'handlers',
        component: EmailHandlerComponent
    },
    { path: '**', component: NoPageFoundComponent }
];
@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            preloadingStrategy: PreloadAllModules,
            scrollPositionRestoration: 'enabled'
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
