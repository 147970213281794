import { Observable } from 'rxjs';
import { Injectable, isDevMode } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AppGlobals } from '../../shared/constants';
import { Entity } from '../../models/abstract-entity.model';
import { EntityType } from '../../shared/enums/enums.enum';
import { ErrorHandlerService } from '../../services/shared/error-handler/error-handler.service';
import { creditCardValidatorDTO, requestDTO } from './../../interfaces/promotions.interface';

@Injectable({
	providedIn: 'root'
})
export class CloudService {

	afFunction$: Observable<any>;
	loading: boolean;

	constructor(
		private aff: AngularFireFunctions,
		private errorService: ErrorHandlerService
	) {
		this.loading = false;
	}

	/**
	 * Activa la CF userCreationByAdmin(data)
	 * @param data Datos para mandar a la CF.
	 * Puede ser un objeto Rent o cualquier cosa que tenga al menos:
	 * - lesseeEmail
	 * - lesseeRut
	 * - lesseeFullName
	 * - adminFullName
	 */
	userCreationByAdmin(data: any): void {
		const sendingData = {
			lesseeEmail: data.lesseeEmail,
			lesseeRut: data.lesseeRut,
			lesseeFullName: data.lesseeFullName,
			adminFullName: data.adminFullName,
			displayCode: AppGlobals.makeRandomCode(10),
			tipoEmail: 'invitations-email'
		};
		const callable = this.aff.httpsCallable('userCreationByAdmin');
		const afFunction$: Observable<any> = callable(sendingData);
		afFunction$.subscribe(
			(res) => {
				//Do something
			},
			(err) => {
				this.errorService.recordError(err, 'cloud.service.ts',
					'afFunction$.subscribe()', 'Error en subscribe de cloud function');
			}
		);
	}

	async uploadAdminFile(data: any): Promise<any> {
		const sendingData = data;
		const callable = this.aff.httpsCallable('sendPaymentLinkOrchestrator');
		this.afFunction$ = callable(sendingData);
		const res = await this.afFunction$.toPromise();
		return res;
  	}


	commissionRobot(
		entities:
			| Entity[]
			| Array<{ id: string; value: number; entityType: EntityType }>,
		userId?: string,
	): Observable<Array<any>> {
		const sendingData = { entities };
		const callable = this.aff.httpsCallable('commissionRobot');
		const afFunction$: Observable<any> = callable(sendingData);
		return afFunction$;
	}

	async saveUserSurveys(data: any): Promise<any> {
		const sendingData = data;
		const callable = this.aff.httpsCallable('saveUserSurveys');
		this.afFunction$ = callable(sendingData);
		const res = await this.afFunction$.toPromise();
		return res;
  }

  
	async creditCardValidator(data: any): Promise<any> {
		const sendingData: creditCardValidatorDTO = { promotionCode: data.promotionCode, lastCodeNumbers: data.lastCodeNumbers , bank: data.bank};
		const callable = this.aff.httpsCallable('creditCardValidator');
		this.afFunction$ = callable(sendingData);
		const res = await this.afFunction$.toPromise();
		return res;
  }

  async promotionValidator(data: requestDTO): Promise<any> {
	const sendingData = data;
	const callable = this.aff.httpsCallable('promotionValidator');
	this.afFunction$ = callable(sendingData);
	const res = await this.afFunction$.toPromise();
	return res;
}

  async suggestNewEntity(data: any): Promise<any> {
	const sendingData =  data;
	const callable = this.aff.httpsCallable('entityTypeRequest');
	this.afFunction$ = callable(sendingData);
	const res = await this.afFunction$.toPromise();
	return res;
	}

	async updatePinMethod(data: any): Promise<any> {
		const sendingData =  data;
		const callable = this.aff.httpsCallable('updatePinMethod');
		this.afFunction$ = callable(sendingData);
		const res = await this.afFunction$.toPromise();
		return res;
	}

	async userRegistrationConsistency(data: any): Promise<any> {
		const sendingData =  data;
		const callable = this.aff.httpsCallable('userRegistrationConsistency');
		this.afFunction$ = callable(sendingData);
		const res = await this.afFunction$.toPromise();
		return res;
	}

	async callCF_rutValidator(data: any): Promise<any> {
		const sendingData = { rut: data, action: 'enroll' };
		const callable = this.aff.httpsCallable('rutHandler');
		this.afFunction$ = callable(sendingData);
		const res = await this.afFunction$.toPromise();
		return res;
	}

	async rutValidator(data: any): Promise<any> {
		const sendingData = { rut: data };
		const callable = this.aff.httpsCallable('rutValidator');
		this.afFunction$ = callable(sendingData);
		const res = await this.afFunction$.toPromise();
		return res;
	}

	async changeUserEmail(currentEmail: string, newEmail: string): Promise<any> {
		const sendingData = { currentEmail, newEmail };
		const callable = this.aff.httpsCallable('changeUserEmail');
		this.afFunction$ = callable(sendingData);
		const res = await this.afFunction$.toPromise();
		return res;
	}

	async rutEnroller(data: any): Promise<any> {
		const sendingData = { rut: data };
		const callable = this.aff.httpsCallable('rutEnroller');
		this.afFunction$ = callable(sendingData);
		const res = await this.afFunction$.toPromise();
		return res;
	}

	async userDocCreate(data?: any): Promise<any> {
		const sendingData = { ip: data };
		const callable = this.aff.httpsCallable('coldStarts-userDocCreate');
		this.afFunction$ = callable(sendingData);
		const res = await this.afFunction$.toPromise();
		return res;
	}

	async entityDocCreate(data: EntityType): Promise<any> {
		const sendingData = { entityType: data };
		const callable = this.aff.httpsCallable('coldStarts-entityDocCreate');
		this.afFunction$ = callable(sendingData);
		const res = await this.afFunction$.toPromise();
		return res;
	}

	async recaptchaVerify(token: any): Promise<any> {
		const sendingData = { token };
		const callable = this.aff.httpsCallable('recaptchaVerify');
		this.afFunction$ = callable(sendingData);
		const res = await this.afFunction$.toPromise();
		return res;
	}

  async engagementBigqueryMetrics(data: any): Promise<any> {
		const callable = this.aff.httpsCallable('engagementBigqueryMetrics');
		this.afFunction$ = callable(data);
    const res = await this.afFunction$.toPromise();
		return res;
	}

	async wakeUpRutValidator(): Promise<any> {
		const sendingData = { rut: '', action: 'enroll' };
		const callable = this.aff.httpsCallable('rutHandler');
		this.afFunction$ = callable(sendingData);
		const res = await this.afFunction$.toPromise();
		return res;
	}

	async checkValidReferralsIds(invitationCode: string): Promise<boolean> {
		const sendingData = { invitationCode };
		const callable = this.aff.httpsCallable('checkValidReferralsIds');
		this.afFunction$ = callable(sendingData);
		const {
			status,
			isInvitationCodeValid
		} = await this.afFunction$.toPromise();
		return isInvitationCodeValid;
	}

}
