import { HttpClientModule } from '@angular/common/http';
import { AngularMaterialModule } from './angular-material/angular-material.module';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoPageFoundComponent } from './no-page-found/no-page-found.component';
import { NotAuthenticatedComponent } from './not-authenticated/not-authenticated.component';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';
import { LoadingComponent } from './loading/loading.component';
import { PreloadingComponent } from './preloading/preloading.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { PipesModule } from '../pipes/pipes.module';

@NgModule({
  declarations: [
    NoPageFoundComponent,
    NotAuthorizedComponent,
    NotAuthenticatedComponent,
    LoadingComponent,
    PreloadingComponent,
    SpinnerComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    AngularMaterialModule,
    HttpClientModule,
    PipesModule,
  ],
  exports: [
    NoPageFoundComponent,
    NotAuthorizedComponent,
    NotAuthenticatedComponent,
    LoadingComponent,
    PreloadingComponent,
    SpinnerComponent,
  ]
})
export class SharedModule {}
