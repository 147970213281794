import { Title, Meta, MetaDefinition } from '@angular/platform-browser';
import { ActivationEnd, Router, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Component } from '@angular/core';
import { Observable, Observer, fromEvent, merge } from 'rxjs';
import { SweetalertService } from './services/shared/sweetalert/sweetalert.service';
import { AngularFireRemoteConfig } from '@angular/fire/remote-config';
import { ErrorHandlerService } from './services/shared/error-handler/error-handler.service';
import { CloudService } from '@services/cloud/cloud.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { UserService } from '@services/user/user.service';
import { IntercomService } from '@services/shared/intercom/intercom.service';
declare var heap: any;
export interface Food {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  titulo = 'neat-app';
  wasOffline = false;
  activeLang: string;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private title: Title,
    private meta: Meta,
    private swalService: SweetalertService,
    private fireRemoteConfig: AngularFireRemoteConfig,
    private errorService: ErrorHandlerService,
    private clouService: CloudService,
    private firestoreService: AngularFirestore,
    private fireAnalitycs: AngularFireAnalytics,
    private userService: UserService,
    private intercomService: IntercomService
  ) {
    this.fireRemoteConfig.fetchAndActivate()
    .catch(err => {
      this.errorService.recordError(err, 'app.component.ts',
        'this.fireRemoteConfig.fetchAndActivate()', 'Error al conectarse con Remote Config');
      // console.error('Error al conectarse con Remote Config', err);
    });
    this.firestoreService.firestore.enablePersistence()
    .catch(err => {
      this.fireAnalitycs.logEvent(`firestore.enablePersistence() error: ${err.code}`);
        //err.code = failed-precondition:
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
        //err.code = unimplemented:
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
    });
    this.route.queryParams.subscribe(params => {
      if (params['template'] && params['action'] && params['email']){
        this.clouService.engagementBigqueryMetrics(Object.assign({},params));
      }
      if(params['from']){
        try{
          heap.addUserProperties({
            'comesFrom': params['from']
          });
        } catch(e){
          this.errorService.recordError(e, 'app.component.ts',
          `heap.addUserProperties()', 'Error al asignar heap property`);
        }
      }
    });
    this.getDataRoute().subscribe(data => {
      this.titulo = data.titulo;
      this.title.setTitle(this.titulo);

      const metaTag: MetaDefinition = {
        name: 'description',
        content: this.titulo
      };
      this.meta.updateTag(metaTag);
    });
    this.createOnline$().subscribe(isOnline => {
      if (!isOnline) {
        this.swalService.swalToastGeneral('Uups, parece que no tienes conexión a internet, algunas funcionalidades pueden dejar de funcionar', 'warning');
        this.wasOffline = true;
      } else if (isOnline && this.wasOffline) {
        this.swalService.swalToastGeneral('Genial, volviste a tener conexión', 'success');
        this.wasOffline = false;
      }
    });
    window.addEventListener('appinstalled', (evt) => {
      this.fireAnalitycs.logEvent(`App installed`);
    });
    this.intercomService.initIntercom();
  }

  createOnline$() {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      })
    );
  }

  getDataRoute() {
    return this.router.events.pipe(
      filter(evento => evento instanceof ActivationEnd),
      filter((evento: ActivationEnd) => evento.snapshot.firstChild === null),
      map((evento: ActivationEnd) => evento.snapshot.data)
    );
  }

  loadLanguaje() {
    if (localStorage.getItem('languaje')) {
      this.activeLang = JSON.parse(localStorage.getItem('languaje'));
    }
  }
}
