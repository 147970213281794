import { NULL_EXPR } from '@angular/compiler/src/output/output_ast';

/**
 * Nombres de los planes de neat.
 *
 * Valores de keys tienen el mismo nombre que sus keys
 *
 * @property {string} flex - Valor: "flex"
 * @property {string} standard - Valor: "standard"
 */
export enum PlanName {
  flex = 'flex', // "plan neat"
  standard = 'standard'
}

/**
 * Commission fees
 *
 * Los valores son los precios de los planes dependiendo del nombre del plan
 *
 * @property {number} flex - Valor: 2990
 * @property {number} standard - Valor: 1990
 */
export enum PlanCommission {
  flex = 2990,
  standard = 1990
}

/**
 * Días de demora según el plan
 *
 * @property {number} flex - Valor: 3
 * @property {number} standard - Valor: 7
 *
 */
export enum PlanDay {
  flex = 3, // "plan neat"
  standard = 7
}

/**
 * String Enum con estados posibles de virtualRents
 */
export enum CurrentPaymentStatus {
  waiting = 'waiting',
  process = 'process',
  done = 'done',
  late = 'late',
  error = 'error'
}

/**
 * String enum con estados posibles del pago
 */
export enum PaymentStatus {
  Created = 'Created',
  InProgress = 'InProgress',
  Done = 'Done',
  Error = 'Error',
  UnexpectedError = 'UnexpectedError',
  Cancelled = 'Cancelled',
}



/**
 * String enum de entidades pagables
 */
export enum EntityType {
  rent           = 'rent',
  commonExpenses = 'commonExpenses',
  warehouse      = 'warehouse',
  parking        = 'parking',
  mortgageFoot   = 'mortgageFoot',
  kindergarten   = 'kindergarten',
  school         = 'school',
  domesticWorker = 'domesticWorker',
  nursingHome    = 'nursingHome',
  sportClub      = 'sportClub',
  socialClub     = 'socialClub',
  gym            = 'gym',
  professionals  = 'professionals',
  alimony        = 'alimony',
  others         = 'others'
}

export enum BasicServicesEnum {
  basicServices = 'basicServices'
}

export enum EntityCollectionName {
  rent           = 'virtualRents',
  commonExpenses = 'commonExpenses',
  warehouse      = 'warehouses',
  parking        = 'parkings',
  mortgageFoot   = 'mortgageFeet',
  kindergarten   = 'kindergartens',
  school         = 'schools',
  domesticWorker = 'domesticWorkers',
  nursingHome    = 'nursingHomes',
  sportClub      = 'sportClubs',
  socialClub     = 'socialClubs',
  gym            = 'gyms',
  professionals  = 'professionals',
  alimony        = 'alimony',
  others         = 'entities',
}

export enum BasicServiceCollectionName {
  basicServices  = 'basicServices',
}

export enum PaymentCollectionName {
  rent           = 'rentsPayments',
  commonExpenses = 'commonExpensesPayments',
  warehouse      = 'warehousesPayments',
  parking        = 'parkingsPayments',
  mortgageFoot   = 'mortgageFeetPayments',
  kindergarten   = 'kindergartensPayments',
  school         = 'schoolsPayments',
  domesticWorker = 'domesticWorkersPayments',
  nursingHome    = 'nursingHomesPayments',
  sportClub      = 'sportClubsPayments',
  socialClub     = 'socialClubsPayments',
  gym            = 'gymsPayments',
  professionals  = 'professionalsPayments',
  alimony        = 'alimonyPayments',
  others         = 'payments',
}

/**
 * String enum de nombres de las entidades en español.
 * Usable con EntityType:
 * @example
 * EntityTypeLocale[EntityType.rent] // "arriendo"
 */
export enum EntityTypeLocaleList {
  rent = 'Arriendo',
  commonExpenses = 'Gasto común',
  warehouse = 'Bodega',
  parking = 'Arriendo de estacionamiento',
  mortgageFoot = 'Crédito de inmobiliaria o Muturaria',
  kindergarten = 'Jardín infantil',
  school = 'Colegio',
  domesticWorker = 'Asesora del hogar',
  nursingHome = 'Residencia de mayores',
  sportClub = 'Club deportivo',
  socialClub = 'Club social',
  gym = 'Gimnasio',
  professionals = 'Servicios profesionales',
  alimony = 'Pensión alimenticia',
  others = 'Otros',
}

export enum EntityTypeLocale {
  rent           = 'arriendo',
  commonExpenses = 'gasto común',
  warehouse      = 'bodega',
  parking        = 'estacionamiento',
  mortgageFoot   = 'cuota del pie',
  kindergarten   = 'jardín infantil',
  school         = 'colegio',
  // university     = 'universidad',
  domesticWorker = 'asesora del hogar',
  nursingHome    = 'residencia de mayores',
  sportClub      = 'club deportivo',
  socialClub     = 'club social',
  gym            = 'gimnasio',
  professionals  = 'servicios profesionales',
  alimony        = 'pensión alimenticia',
  others         = 'otros',
}

/**
 * String enum de colores para el pieChart
 */
export enum EntityTypeColor {
  rent = '#462eff',
  commonExpenses = '#00b362',
  warehouse = '#ff6557',
  parking = '#ffd92e',
  mortgageFoot = '#47ff85',
  kindergarten = '#14d2eb',
  school = '#CF9BFF',
  university = '#FFA767',
  domesticWorker = '#b31800',
  nursingHome = '#7357ff',
  sportClub = '#92a0f2',
  socialClub = '#fff5eb',
  gym = '#cacbd3',
  professionals = '#420420',
  alimony = '#0e0f21',
  others = '#D3D3D3',
}

export enum ProfessionalService {
  lawyer = 'abogado',
  dentist = 'odontólogo',
  gardener = 'jardinero',
  plumber = 'gásfiter',
  psychologist = 'psicólogo',
  accountant = 'contador',
  architect = 'arquitecto'
}

export enum DeleteUserReason {
  dontLikeBenefits = 'No te parecen convenientes los beneficios',
  notUsefulNow = 'No te sirve en este momento',
  notUsefulEntities = 'No tienes cuentas que te interesen pagar en Neat',
  appTroubles = 'Tuviste problemas con el funcionamiento de Neat',
  paymentTroubles = 'Tuviste problemas con algún pago particular',
  others = 'Otro',
}

/**
 * Tipos de usuarios en neat
 */
export enum UserType {
  lessee = 'LESSEE',
  admin = 'ADMIN',
  owner = 'OWNER',
  lesseeadmin = 'LESSEEADMIN'
}

export enum UserTypeLocale {
  ADMIN = 'Administrador',
  OWNER = 'Propietario'
}

export enum PaymentProduct {
  oneclick = 'OneClick',
  servipag = 'Servipag',
  webpayplus = 'Webpay Plus'
}

export enum AuthProvider {
  firebase = 'firebase',
  google = 'google',
}

export enum PaymentErrorFront {
  invalidParameters = 1,
}

export enum NeatErrors {
  TbkStrangeError = 'Ha surgido un problema para procesar el pago, por favor contáctanos a nuestro chat.',
  TbkDailyAmount = 'Ha superado el monto máximo diario de pago con esta tarjeta.',
  TbkTotalAmount = 'Monto máximo de pago excedido con esta tarjeta.',
  TbkTotalPayments = 'Máxima cantidad de pagos diarios con esta tarjeta excedido.',
  TbkInvalidInstallments = 'Con esta tarjeta no puedes pagar en cuotas. Recuerda que tarjetas de débito o prepago no pueden pagar con cuotas.',
  NeatUnauthorized = 'Usuario no autorizado a realizar pagos.',
  NeatDocNotFound = 'Problemas de configuración.'
}

export enum NeatCodeErrors {
  TbkStrangeError = -1000,
  TbkDailyAmount = -1001,
  TbkTotalAmount = -1002,
  TbkTotalPayments = -1003,
  TbkInterruptsCommunication = -100,
  TbkServerError = -101,
  TbkInvalidInstallments = -1004,
  NeatUnauthorized = -1100,
  NeatDocNotFound = -1101,
}

export enum BasicServicesType {
  gas = 'gas',
  light = 'light',
  highway = 'highway',
  water = 'water',
  telecomunications = 'telecomunications',
  telephony = 'telephony'
}

export enum ServicesTypeLocale {
  gas = 'Gas',
  water = 'Agua',
  light = 'Luz',
  highway = 'Tag',
  telecomunications = 'Internet/Telefonia/TV',
  telephony = 'Telefonía Celular'
}


export enum UserCategory {
  gold = 'gold',
  platinum = 'platinum',
  black = 'black',
  diamond = 'diamond',
  infinite = 'infinite',
  withoutCategory = 'sin categoria'
}

export enum UserNextCategory {
  sin_categoria = 'gold',
  gold = 'platinum',
  platinum = 'black',
  black = 'diamond',
  diamond = 'infinite',
  infinite = 'infinite',
}

export enum PromotionPeriodDates {
  ripley_start = '12/28/2020 00:00:00',
  ripley_finish = '06/30/2021 23:59:59',
  itau_start = '02/01/2021 00:00:00',
  itau_finish = '03/31/2021 23:59:59',
  superDigital_start = '12/16/2020 00:00:00',
  superDigital_finish = '03/30/2021 23:59:59',
  falabella_start = '05/01/2021 00:00:00',
  falabella_finish = '10/31/2021 23:59:59',
}


export enum BasicServicesIcons {
  gas = 'local_fire_department',
  water = 'opacity',
  light = 'flash_on',
  highway = 'directions_car',
  telecomunications = 'home',
  telephony = 'stay_current_portrait'
}

export enum ServicesNames {
  aguas_araucanía = 'aguas_araucanía',
  aguas_andinas = 'aguas_andinas',
  aguas_antofagasta = 'aguas_antofagasta',
  essbio = 'essbio',
  esval = 'esval',
  smapa = 'smapa',
  aguas_sepra = 'aguas_sepra',
  nuevo_sur = 'nuevo_sur',
  aguas_manquehue = 'aguas_manquehue',
  aguas_santiago_poniente = 'aguas_santiago_poniente',
  essal = 'essal',
  aguas_san_pedro = 'aguas_san_pedro',
  aguas_cordillera = 'aguas_cordillera',
  aguas_del_valle = 'aguas_del_valle',
  nueva_atacama = 'nueva_atacama',
  aguas_los_guaicos = 'aguas_los_guaicos',
  aguas_del_altiplano = 'aguas_del_altiplano',
  aguas_magallanes = 'aguas_magallanes',
  gasco_en_linea = 'gasco_en_linea',
  abastible = 'abastible',       
  abastible_granel_cliente = 'abastible_granel_cliente',
  abastible_granel_documento = 'abastible_granel_documento',
  abastible_granel_rut = 'abastible_granel_rut',
  metrogas = 'metrogas',
  energas = 'energas',
  lipigas = 'lipigas',
  lipigas_medidores ='lipigas_medidores',
  lipigas_granel = 'lipigas_granel',
  abastible_medidores = 'abastible_medidores',
  gasvalpo = 'gasvalpo',
  autopistas_unificadas_tag = 'autopistas_unificadas_tag',
  valles_del_bio_bio_rut = 'valles_del_bio_bio_rut',
  enel_colina = 'enel_colina',
  saesa = 'saesa',
  frontel = 'frontel',
  edelmag_en_linea = 'edelmag_en_linea',
  luz_parral = 'luz_parral',
  eepa = 'eepa',
  luz_litoral = 'luz_litoral',
  luz_casablanca = 'luz_casablanca',
  cec = 'cec',
  luz_linares = 'luz_linares',
  edelaysen = 'edelaysen',
  luz_osorno = 'luz_osorno',
  cge = 'cge',
  chilquinta = 'chilquinta',
  enel = 'enel',
  codiner = 'codiner',
  coopelan = 'coopelan',
  claro = 'claro',
  claro_hogar_documento = 'claro_hogar_documento',
  claro_tv_satelital_documento = 'claro_tv_satelital_documento',
  claro_hogar_rut = 'claro_hogar_rut',
  movistar = 'movistar',
  movistar_hogar_teléfono = 'movistar_hogar_teléfono',
  movistar_empresas_facturas = 'movistar_empresas_facturas',
  movistar_empresas_avisos = 'movistar_empresas_avisos',
  movistar_hogar_rut = 'movistar_hogar_rut',
  directv = 'directv_mensualidades',
  directv_mensualidades_rut = 'directv_mensualidades_rut',
  directv_mensualidades_suscriptor = 'directv_mensualidades_suscriptor',
  entel_sa = 'entel_sa',
  entel_carrier = 'entel_carrier',
  vtr = 'vtr',
  vtr_rut = 'vtr_rut',
  vtr_cuenta = 'vtr_cuenta',
  gtd = 'gtd',
  gtd_medios = 'gtd_medios',
  gtd_telesat = 'gtd_telesat',
  gtd_rtc = 'gtd_rtc',
  gtd_larga_distancia = 'gtd_larga_distancia',
  gtd_teleductos = 'gtd_teleductos',
  gtd_rancagua = 'gtd_rancagua',
  gtd_manquehue = 'gtd_manquehue',
  mundo_pacífico = 'mundo_pacífico',
  telefónica_del_sur = 'telefónica_del_sur',
  tu_ves = 'tu_ves',
  cmet = 'cmet',
  wom = 'wom',
  entel = 'entel',
  movistar_celular_rut = 'movistar_celular_rut',
  entel_celular = 'entel_celular',
  claro_móvil = 'claro_móvil',
  claro_móvil_celular = 'claro_móvil_celular',
  claro_móvil_rut = 'claro_móvil_rut',
  claro_móvil_documento = 'claro_móvil_documento',
}

export enum ServicesNamesLocale {
  aguas_araucanía = 'Aguas Araucanía',
  aguas_andinas = 'Aguas Andinas',
  aguas_antofagasta = 'Aguas Antofagasta',
  essbio = 'Essbio',
  esval = 'Esval',
  smapa = 'Smapa',
  aguas_sepra = 'Aguas Sepra',
  nuevo_sur = 'Nuevo Sur',
  aguas_manquehue = 'Aguas Manquehue',
  aguas_santiago_poniente = 'Aguas Santiago Poniente',
  essal = 'Essal',
  aguas_san_pedro = 'Aguas San Pedro',
  aguas_cordillera = 'Aguas Cordillera',
  aguas_del_valle = 'Aguas del Valle',
  nueva_atacama = 'Nueva Atacama',
  aguas_los_guaicos = 'Aguas los Guaicos',
  aguas_del_altiplano = 'Aguas del Altiplano',
  aguas_magallanes = 'Aguas Magallanes',
  gasco_en_linea = 'Gasco',
  abastible = 'Abastible',       
  abastible_granel_cliente = 'Abastible Granel - Nro Cliente',
  abastible_granel_documento = 'Abastible Granel - Nro Documento',
  abastible_granel_rut = 'Abastible Granel - Rut',
  metrogas = 'Metrogas',
  energas = 'Energas',
  lipigas = 'Lipigas',
  lipigas_medidores ='Lipigas Medidores',
  lipigas_granel = 'Lipigas Granel',
  abastible_medidores = 'Abastible Medidores',
  gasvalpo = 'Gasvalpo',
  autopistas_unificadas_tag = 'Autopistas Santiago',
  valles_del_bio_bio_rut = 'Valles del Bio Bio',
  enel_colina = 'Enel Colina',
  saesa = 'Saesa',
  frontel = 'Frontel',
  edelmag_en_linea = 'Edelmag En Linea',
  luz_parral = 'Luz Parral',
  eepa = 'Eepa',
  luz_litoral = 'Luz Litoral',
  luz_casablanca = 'Luz Casablanca',
  cec = 'CEC',
  luz_linares = 'Luz Linares',
  edelaysen = 'Edelaysen',
  luz_osorno = 'Luz Osorno',
  cge = 'CGE',
  chilquinta = 'Chilquinta',
  enel = 'Enel',
  codiner = 'Codiner',
  coopelan = 'Coopelan',
  claro = 'Claro',
  claro_hogar_documento = 'Claro Hogar - Documento',
  claro_tv_satelital_documento = 'Claro Tv Satelital - Documento',
  claro_hogar_rut = 'Claro Hogar - Rut',
  movistar = 'Movistar',
  movistar_hogar_teléfono = 'Movistar Hogar - Teléfono',
  movistar_empresas_facturas = 'Movistar Empresas - Facturas',
  movistar_empresas_avisos = 'Movistar Empresas - Avisos',
  movistar_hogar_rut = 'Movistar Hogar Rut',
  directv = 'Directv',
  directv_mensualidades_rut = 'Directv Mensualidades - Rut',
  directv_mensualidades_suscriptor = 'Directv Mensualidades - Suscriptor',
  entel_sa = 'Entel S.A.',
  entel_carrier = 'Entel Carrier',
  vtr = 'VTR',
  vtr_rut = 'VTR - Rut',
  vtr_cuenta = 'VTR - Cuenta',
  gtd = 'GTD',
  gtd_medios = 'GTD Medios',
  gtd_telesat = 'GTD Telesat',
  gtd_rtc = 'GTD RTC',
  gtd_larga_distancia = 'GTD Larga Distancia',
  gtd_teleductos = 'GTD Teleductos',
  gtd_rancagua = 'GTD Rancagua',
  gtd_manquehue = 'GTD Manquehue',
  mundo_pacífico = 'Mundo Pacífico',
  telefónica_del_sur = 'Telefónica del Sur',
  tu_ves = 'Tu Ves',
  cmet = 'Cmet',
  wom = 'Wom',
  movistar_celular_rut = 'Movistar',
  entel = 'Entel',
  entel_celular = 'Entel',
  claro_móvil = 'Claro',
  claro_móvil_celular = 'Claro Móvil - Celular',
  claro_móvil_rut = 'Claro Móvil - Rut',
  claro_móvil_documento = 'Claro Móvil - Documento',
}

export enum ServicesCodes {
  aguas_araucanía = 1064,
  aguas_andinas = 1018,
  aguas_antofagasta = 1232,
  essbio = 1284,
  esval = 1155,
  smapa = 1041,
  aguas_sepra = 1504,
  nuevo_sur = 1314,
  aguas_manquehue = 1188,
  aguas_santiago_poniente = 1321,
  essal = 1091,
  aguas_san_pedro = 1191,
  aguas_cordillera = 1128,
  aguas_del_valle = 1222,
  nueva_atacama = 1404,
  aguas_los_guaicos = 1414,
  aguas_del_altiplano = 1164,
  aguas_magallanes = 1264,
  gasco_en_linea = 4518,        
  abastible_granel_cliente = 4314, 
  abastible_granel_documento = 4316, 
  abastible_granel_rut = 4315, 
  metrogas = 4015, 
  energas = 4113, 
  lipigas_medidores = 4425, 
  lipigas_granel = 4422, 
  abastible_medidores = 4313, 
  gasvalpo = 4112, 
  autopistas_unificadas_tag = 6500,
  vespucio_sur = 6548,
  vespucio_norte = 6533,
  costanera_norte = 6528,
  autopase_autopista_central = 6512,
  valles_del_bio_bio_rut = 6561,
  autovia_stgo_lampa_globalvia = 6571,
  enel_colina = 2514,
  saesa = 2411,
  frontel = 2441,
  edelmag_en_linea = 2325,
  luz_parral = 2112,
  eepa = 2501,
  luz_litoral = 2142,
  luz_casablanca = 2132,
  cec = 2713,
  luz_linares = 2122,
  edelaysen = 2431,
  luz_osorno = 2421,
  cge = 2345,
  chilquinta = 2102,
  enel = 2018,
  codiner = 2611,
  coopelan = 2601,
  claro_hogar_documento = 3912,
  claro_tv_satelital_documento = 3752,
  claro_hogar_rut = 3911,
  movistar_hogar_teléfono = 3048,
  movistar_empresas_facturas = 3032,
  movistar_empresas_avisos = 3033,
  movistar_hogar_rut = 3058,
  directv_mensualidades_rut = 3711,
  directv_mensualidades_suscriptor = 3712,
  entel_sa = 3137,
  entel_carrier = 3151,
  vtr_rut = 3601,
  vtr_cuenta = 3602,
  gtd_medios = 3463,
  gtd_telesat = 3465,
  gtd_rtc = 3466,
  gtd_larga_distancia = 3462,
  gtd_teleductos = 3464,
  gtd_rancagua = 3414,
  gtd_manquehue = 3467,
  mundo_pacífico = 3458,
  telefónica_del_sur = 3418,
  tu_ves = 3721,
  cmet = 3351,
  wom = 3348,
  movistar_celular_rut = 3068,
  entel_celular = 3116,
  claro_móvil_celular = 3513,
  claro_móvil_rut = 3511,
  claro_móvil_documento = 3512,
}

export enum ClientCodeTypes {
  cliente = 'Número de Cliente',
  documento = 'Número de Documento',
  rut = 'Rut',
  facturas = 'Facturas',
  avisos = 'Avisos',
  teléfono = 'Teléfono',
  celular = 'Celular',
  cuenta = 'Cuenta',
  suscriptor = 'Suscriptor'
}

export enum Categories {
  medios = 'Medios',
  telesat = 'Telesat',
  rtc = 'RTC',
  larga_distancia = 'Larga Distancia',
  teleductos = 'Teleductos',
  rancagua = 'Rancagua',
  manquehue = 'Manquehue',
  hogar = 'Hogar',
  tv_satelital = "TV Satelital",
  granel = 'Granel',
  medidores = 'Medidores',
  empresas = 'Empresas'
}

/**
 * Currency con que se paga la entidad
 */
export enum PayingAccountUnit {
  CLP = 'CLP',
  UF = 'UF'
}

/**
 * Identificador interno del tipo de entidad
 */
export enum ProductNumber {
  rentDev = '01',
  rentProd = '02',
  commonExpensesDev = '03',
  commonExpensesProd = '04',
  warehouseDev = '05',
  warehouseProd = '06',
  parkingDev = '07',
  parkingProd = '08',
  mortgageFootDev = '09',
  mortgageFootProd = '10',
  kindergartenDev = '11',
  kindergartenProd = '12',
  schoolDev = '13',
  schoolProd = '14',
  universityDev = '15',
  universityProd = '16',
  domesticWorkerDev = '17',
  domesticWorkerProd = '18',
  nursingHomeDev = '19',
  nursingHomeProd = '20',
  sportClubDev = '21',
  sportClubProd = '22',
  socialClubDev = '23',
  socialClubProd = '24',
  gymDev = '25',
  gymProd = '26',
  professionalsDev = '27',
  professionalsProd = '28',
  alimonyDev = '29',
  alimonyProd = '30',
  donationsDev = '31',
  donationsProd = '32'
}

export enum PromotionEntity {
  cmr = 'CMR',
  falabella = 'Falabella',
  bci = 'BCI',
  itau = 'Itau',
  mastercard = 'Mastercard',
  neat = 'neat',
  ripley = 'Ripley',
  superDigital = 'SuperDigital',
  administradorOnline = 'AdministradorOnline',
}

export enum DeviceType {
  desktop = 'Desktop',
  mobile = 'Mobile',
  tablet = 'Tablet',
  unknown = 'Unknown'
}

/**
 * Se utiliza para ordenar lista de entidades
 */
export enum sortListLocale {
  createDate = 'Más reciente',
  id = 'ID',
  entityTypeLocale = 'Tipo de cuenta',
  address = 'Dirección',
  value = 'Monto',
  recieverFullName= 'Nombre de destinatario',
}

/**
 * Se utiliza para ordenar lista de pagos
 */
export enum sortPaymentListLocale {
  createDate = 'Más reciente',
  paymentId = 'ID',
  entityType = 'Tipo de cuenta',
  propertyName = 'Dirección',
  value = 'Monto',
  // recieverFullName= 'Nombre de destinatario',
}

export enum DisableEntityStatus {
  duplicate = 'Tipo de cuenta ya fue deshabilitada',
  success = 'Tu cuenta ha sido deshabilitada exitosamente',
  notFound = 'La cuenta no pudo ser encontrada',
  expireTimeExceed = 'Tiempo de expiracion excedido',
  retry = 'Favor, intenta mas tarde',
  invalidMessage = 'Mensaje inválido'
}

export enum EntriesIds {
  pagarCuentasOnline = '7IFctVzVzSiuZwtaSGIp3x'
}

export enum EntriesIdsBeta {
  pagarCuentasOnline = 'igM0LiOe9xjyqhkAu456G'
}