import { Observable } from 'rxjs';
import { DialogService } from './../../services/shared/dialog/dialog.service';
import { Injectable } from '@angular/core';
import {
    CanActivate,
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';
import { UserService } from 'app/services/user/user.service';

@Injectable({
    providedIn: 'root'
})
export class LoginGuard implements CanActivate {
    constructor(private router: Router,
                private dialogService: DialogService,
                private userService: UserService ) {}
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | Observable<boolean> | Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            const currentUser = await this.userService.getCurrentUser();
            if (!currentUser) {
              this.router.navigate(['']);
              resolve(false);
            } else {
              const userInfoDoc = await this.userService.getUserInfoDoc(currentUser.id);
              if (userInfoDoc.rut) {
                  resolve(true);
              } else {
                  this.router.navigate(['registrar/registrogoogle']);
                  resolve(false);
              }
            }
        });
    }
}
