import { Deserializable } from '../../interfaces/deserializable.interface';
import { User } from './user.model';

export class UserInfo extends User implements Deserializable {
  public hasPayed: boolean;
  public blockedRuts: Array<string>;
  public fraud: boolean;
  public suspectHistory: Array<SuspectHistory>;
  public deletedEmail?: string;
  public fraudSuspect: boolean;
}

class SuspectHistory {
  fraudType: string;
  timestamp: Date;
}
