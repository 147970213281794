import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import {
  AngularFireStorage,
  AngularFireUploadTask
} from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {
  constructor(
    private angularFirestore: AngularFirestore,
    private angularFireStorage: AngularFireStorage
  ) {}

  firestoreNewDoc(
    col: string,
    data: firebase.firestore.DocumentData,
    docID: string = null
  ): Promise<void> {
    if (docID === null) {
      return this.angularFirestore.firestore
        .collection(col)
        .doc()
        .set(data);
    } else {
      return this.angularFirestore.firestore
        .collection(col)
        .doc(docID)
        .set(data);
    }
  }

  firestoreUpdateDoc(
    col: string,
    data: firebase.firestore.UpdateData,
    docID: string
  ): Promise<void> {
    return this.angularFirestore.firestore
      .collection(col)
      .doc(docID)
      .update(data);
  }

  firestoreUpdateEntity(
    col: string,
    data: firebase.firestore.UpdateData,
    docID: string
  ): Promise<void> {
    return this.angularFirestore
      .doc(`${col}/${docID}`)
      .set(data, { merge: true });
  }

  firestoreDeleteDoc(col: string, docID: string): Promise<void> {
    return this.angularFirestore.firestore
      .collection(col)
      .doc(docID)
      .delete();
  }

  storageNewBucket(path: string): AngularFireUploadTask {
    return this.angularFireStorage.ref(path).putString('');
  }

  firestoreGetData(
    collectionName: string,
    docID: string
  ): Promise<firebase.firestore.DocumentSnapshot> {
    return this.angularFirestore.firestore
      .collection(collectionName)
      .doc(docID)
      .get();
  }

  firestoreGetCollection(
    collectionName: string
  ) {
    return this.angularFirestore.firestore
      .collection(collectionName)
      .get();
  }

  firestoreGetRef(
    collectionName: string,
    docID: string
  ): firebase.firestore.DocumentReference {
    return this.angularFirestore.firestore
      .collection(collectionName)
      .doc(docID);
  }

  // Corresponde a la tarea de subida del archivo,
  // que permitirá revisar el porcentaje de carga del archivo
  storageUploadFile(
    path: string,
    file,
    metadata: firebase.storage.UploadMetadata
  ): AngularFireUploadTask {
    return this.angularFireStorage.ref(path).put(file, metadata);
  }

  firestoreQueryDocs(
    col: string,
    parameter: string | firebase.firestore.FieldPath,
    comparison: firebase.firestore.WhereFilterOp,
    value
  ): firebase.firestore.Query {
    // Use Get to get the results once, use onSnapshot place a listener
    return this.angularFirestore.firestore
      .collection(col)
      .where(parameter, comparison, value);
  }
}
