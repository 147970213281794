import { IntercomService } from './../shared/intercom/intercom.service';
import { Router } from '@angular/router';
import { Injectable, Inject, NgZone, isDevMode } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Observable, BehaviorSubject, Subject, of } from 'rxjs';
import { catchError, filter, tap } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { DatabaseService } from './../shared/database/database.service';
import { User } from '../../models/users/user.model';
import { UserInfo } from '../../models/users/user-info.model';
import { SweetalertService } from '../shared/sweetalert/sweetalert.service';
import { CloudService } from '../cloud/cloud.service';
import { ErrorHandlerService } from '../shared/error-handler/error-handler.service';
import { DevicesDetectorService } from '../shared/devices-detector/devices-detector.service';
import { AuthProvider, UserType } from '../../shared/enums/enums.enum';
import { AngularFirePerformance } from '@angular/fire/performance';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { sleep } from '@shared/utils';
import { AngularFirestore } from '@angular/fire/firestore';
import { AppGlobals } from '@shared/constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookiesService } from '@services/cookies/cookies.service';
declare var heap: any;
@Injectable({
  providedIn: 'root'
})
export class UserService {
  sharedUser: Subject<User> = new BehaviorSubject<User>(null);
  sharedUserInfo: Subject<UserInfo> = new BehaviorSubject<UserInfo>(null);
  private sharedIdToken: Subject<string> = new BehaviorSubject<string>(null);
  private providerInfo: Subject<string> = new BehaviorSubject<string>(null);
  private currentUser$: Subject<User> = new BehaviorSubject<User>(null);
  private depositeeInfo$: Subject<any> = new BehaviorSubject<any>(null);
  private associatedPayer$: Subject<any> = new BehaviorSubject<any>(null);
  private associatedPayerEvent$: Subject<any> = new BehaviorSubject<any>(null);
  private subscriptions$: Subject<any> = new BehaviorSubject<any>(null);
  currentUser: User;
  actionCodeSettings = { url: this.document.location.origin + '/dashboard' };
  user: User;
  partialUser: firebase.auth.UserCredential;
  userInfoDoc: UserInfo;
  userPhotoUrl: any;
  userId: string;
  public userSaved: User;
  userRut: string;
  displayName: string; // Analizar si usamos esto
  userInfo: boolean;
  private emailRegisterData = new BehaviorSubject<any>(null);
  currentEmailRegisterData = this.emailRegisterData.asObservable();
  getProviderData: string;
  // authState: any;
  constructor(
    private db: DatabaseService,
    @Inject(DOCUMENT) private document: Document,
    public ngZone: NgZone,
    private devicesService: DevicesDetectorService,
    private fireAuth: AngularFireAuth,
    private router: Router,
    private intercomService: IntercomService,
    private swalService: SweetalertService,
    private cloudService: CloudService,
    private errorService: ErrorHandlerService,
    private firePerformance: AngularFirePerformance,
    private fireAnalitycs: AngularFireAnalytics,
    private afs: AngularFirestore,
    private http: HttpClient,
    private cookiesService: CookiesService,
  ) {
    this.fireAuth.auth.languageCode = 'es';
    this.cargarStorage();
  }

  get user$(): Observable<User> {
    return this.sharedUser.asObservable().pipe(filter(user => !!user));
  }

  get userInfo$(): Observable<UserInfo> {
    return this.sharedUserInfo.asObservable().pipe(filter(user => !!user));
  }

  get idToken$(): Observable<string> {
    return this.sharedIdToken.asObservable().pipe(filter(idToken => !!idToken));
  }

  get provider$(): Observable<string> {
    return this.providerInfo.asObservable().pipe(filter(providerInfo => !!providerInfo));
  }

  get currentUserData$(): Observable<User> {
    return this.currentUser$.asObservable().pipe(filter(user => !!user));
  }

  get getDepositeeInfoData$(): Observable<any> {
    return this.depositeeInfo$.asObservable();
  }

  get associatedPayerData$(): Observable<any> {
    return this.associatedPayer$.asObservable();
  }

  get associatedPayerEventData$(): Observable<any> {
    return this.associatedPayerEvent$.asObservable();
  }

  get subscriptionsData$(): Observable<any> {
    return this.subscriptions$.asObservable();
  }

  setEmailRegisterData(data: any) {
    this.emailRegisterData.next(data);
  }

  getUserData$(uid: string) {
    this.afs.firestore.collection("users").doc(uid).onSnapshot(doc => {
      this.currentUser$.next(doc.data() as User);
    });
  }

  addUser(data: User): void {
    this.sharedUser.next(data);
    localStorage.setItem('user', JSON.stringify(Object.assign({}, data)));
    this.errorService.setUser({ uid: data.id, email: data.email, displayName: data.displayName });
  }

  addUserInfo(data: UserInfo): void {
    this.sharedUserInfo.next(data);
  }

  addIdToken(data: string): void {
    this.sharedIdToken.next(data);
  }

  addProviderInfo(data: string): void {
    this.providerInfo.next(data);
  }

  async updateUserDoc(data: Partial<User>, docID = null): Promise<any> {
    await this.db.firestoreUpdateDoc('users', Object.assign({}, data), docID)
      .catch((err) => {
        this.errorService.recordError(err, 'user.service.ts',
          'firestoreUpdateDoc()', 'Error al updateUserDoc()');
        throw err;
      });
  }

  async updateUserInfoAuth(data: Partial<User>): Promise<any> {
    const { displayName, phoneNumber } = data;
    await this.fireAuth.auth.currentUser.updateProfile({ displayName })
      .catch((err) => {
        this.errorService.recordError(err, 'user.service.ts',
          'updateProfile()', 'Error al actualizar displayName en auth.currentUser');
      });
  }

  /**
   * Alternativas
   * a) fcmToken en Users
   * b) fcmToken en UsersInfo, pero se actualiza a través de una CF
   */
  async updateUserMessagingToken(token: string): Promise<void> {
    await this.db.firestoreUpdateDoc('users', { messagingToken: token }, this.currentUser.id)
      .catch((err) => {
        this.errorService.recordError(err, 'user.service.ts',
          'firestoreUpdateDoc()', 'Error al updateUserFcmToken()');
      });
  }

  getUserProvider() {
    this.fireAuth.auth.onAuthStateChanged(
      async (authUser: firebase.User) => {
        if (authUser) {
          this.addProviderInfo(authUser.providerData[0].providerId);
        }
      }
    );
  }

  private async getUserfromUserDoc(uid: string): Promise<User> {
    try {
      const userDoc = await this.db.firestoreGetData('users', uid)
        .catch((err) => {
          this.errorService.recordError(err, 'user.service.ts',
            'firestoreGetData()', `Error al obenter userDoc: ${uid} and currentUser: ${this.currentUser!.id}`);
          throw err;
        });
      return new User().deserialize(userDoc.data());
    } catch (err) {
      this.errorService.recordError(err, 'user.service.ts',
        'getUserDoc()', 'Error al obtener user desde user doc: ' + uid);
      throw err;
    }
  }

  async waitForUserInfoRut(id: string) {
    const trace = this.firePerformance.trace$('user from waitForUserInfoRut()').subscribe();
    let userInfoDoc = await this.getUserInfoDoc(id);
    while (!userInfoDoc.rut) {
      await sleep(200);
      userInfoDoc = await this.getUserInfoDoc(id);
    }
    trace.unsubscribe();
  }

  // TO DO: this should be a subscribe
  getUserInfoDoc(uid: string): Promise<UserInfo> {
    return new Promise<UserInfo>(async (resolve, reject) => {
      const trace = this.firePerformance.trace$('user from getUserInfoDoc()').subscribe();
      try {
        if (this.userInfoDoc) {
          trace.unsubscribe();
          resolve(this.userInfoDoc);
        } else {
          this.db.firestoreGetRef('usersInfo', uid).onSnapshot(snapShot => {
            if (snapShot) {
              this.userInfoDoc = new UserInfo().deserialize(snapShot.data());
              this.addUserInfo(this.userInfoDoc);
              this.fireAnalitycs.setUserProperties({
                was_referred: this.userInfoDoc.referrerId ? true : false,
                has_referred: this.userInfoDoc.referredWhoPayed.length > 0 ? true : false,
                has_payed: this.userInfoDoc.hasPayed
              });
              trace.unsubscribe();
              resolve(this.userInfoDoc);
            } else {
              trace.unsubscribe();
              resolve(null);
            }
          });
        }
      } catch (err) {
        trace.unsubscribe();
        this.errorService.recordError(err, 'user.service.ts',
          'getUserInfoDoc()', 'Error al obtener el usersInfo doc: ' + uid);
        reject(err);
      }
    });
  }


  // TO DO: this should be a subscribe
  getCurrentUser(): Promise<User> {
    return new Promise<User>((resolve, reject) => {
      const trace = this.firePerformance.trace$('user from getCurrentUser()').subscribe();
      if (this.currentUser === undefined) {
        this.fireAuth.auth.onAuthStateChanged(
          async (authUser: firebase.User) => {
            if (authUser) {
              this.addProviderInfo(authUser.providerData[0].providerId);
              this.fireAnalitycs.setUserId(authUser.uid);
              const userToken = await authUser.getIdToken(true);
              this.addIdToken(userToken);
              this.getUserfromUserDoc(authUser.uid)
                .then((userDoc: User) => {
                  this.currentUser = userDoc;
                  this.fireAnalitycs.setUserProperties({
                    email_verified: this.currentUser.emailVerified,
                    age: this.currentUser.birthDate ? new Date().getFullYear() - this.currentUser.birthDate.year : null
                  });
                  this.userSaved = userDoc;
                  this.intercomService.updateIntercomUser(
                    this.currentUser,
                    Number(authUser.metadata.creationTime) / 1000,
                    Number(authUser.metadata.lastSignInTime) / 1000
                  );
                  this.currentUser.extend_with(authUser);
                  try {
                    this.setHeapConfig(this.currentUser);
                  } catch (e) {
                    trace.unsubscribe();
                    this.errorService.recordError(e, 'user.service.ts',
                      `heap.addUserProperties()', 'Error al asignar heap property to ${authUser.email} in getCurrentUser`);
                  }
                  resolve(this.currentUser);
                })
                .catch(err => {
                  this.errorService.recordError(err, 'user.service.ts',
                    'this.db.firestoreGetData()', 'Error al obtener datos del usuario: ' + authUser.uid);
                  reject(err);
                })
                .finally(() => {
                  trace.unsubscribe();
                });
            } else {
              // No es muy intuitivo, se devuelve null dado que no hay usuario logeado
              resolve(null);
            }
          },
          (error: firebase.auth.Error) => {
            this.errorService.recordError(error, 'user.service.ts',
              'this.fireAuth.auth.onAuthStateChanged', 'Error al obtener user de auth en getCurrentUser()');
            reject(error);
          }
        );
      } else {
        trace.unsubscribe();
        resolve(this.currentUser);
      }
    });
  }

  async logOut(): Promise<void> {
    try {
      await this.fireAuth.auth.signOut()
        .catch((err) => {
          this.errorService.recordError(err, 'user.service.ts',
            'auth.signOut()', 'Error al cerrar sesión');
        });
      this.addIdToken(null);
      window.Intercom('shutdown');
      this.currentUser = null;
      this.borrarStorage();
      this.swalService.swalToastGeneral('Sesión cerrada exitosamente', 'success');
      this.cookiesService.deleteCookie('login', '.neatpagos.com');
      // setTimeout(() => {
      //   window.location.reload();
      // }, 2000);
    } catch (err) {
      this.errorService.recordError(err, 'user.service.ts',
        'logOut()', 'Error al cerrar sesión');
      throw err;
    }
  }

  // Firebase SignInWithPopup
  private async oAuthProvider(provider: firebase.auth.AuthProvider): Promise<firebase.auth.UserCredential> {
    const trace = this.firePerformance.trace$('user from oAuthProvider()').subscribe();
    const signInWithPopup = await this.fireAuth.auth.signInWithPopup(provider)
      .catch((error) => {
        trace.unsubscribe();
        this.errorService.recordError(error, 'user.service.ts',
          'signInWithPopup()', 'Error al iniciar sesión con oAuthProvider');
        throw error;
      });
    trace.unsubscribe();
    return signInWithPopup;
  }

  async hasRut(credentials: firebase.auth.UserCredential) {
    const trace = this.firePerformance.trace$('user from hasRut()').subscribe();
    try {
      this.errorService.setUser(credentials.user);
      const userData = await this.getUserInfoDoc(credentials.user.uid)
        .catch((err) => {
          trace.unsubscribe();
          this.errorService.recordError(err, 'user.service.ts',
            'getUserInfoDoc()', 'Error al obtener userInfo doc para sacar rut');
          throw err;
        });
      if (userData.rut) {
        trace.unsubscribe();
        return true;
      } else {
        trace.unsubscribe();
        return false;
      }
    } catch (err) {
      trace.unsubscribe();
      this.errorService.recordError(err, 'user.service.ts',
        'hasRut()', 'Error al obtener rut');
      throw err;
    }
  }

  getProfileDataFromUser(userDataRaw: string) {
    const userData = JSON.parse(userDataRaw);

    const httpHeaders = {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${userData.credential.oauthAccessToken}`
      })
    };
    return this.http.get<any>(AppGlobals.profileMeEndpoint(userData.user.stsTokenManager.apiKey), httpHeaders).toPromise();
  }

  saveSurveyResponse(data: any): Observable<any> {
		let endpoint: string;
		endpoint = AppGlobals.saveSurveyResponse;
		const httpHeader = {
		  headers: new HttpHeaders({
			'Access-Control-Allow-Origin': '*',
      // 'Origin': 'neatpagos.com',
			'Content-Type': 'application/json',
		  }),
		  observe: "response" as 'body',
		  responseType: 'text' as 'json'
		};
		return this.http.post<any>(endpoint, data, httpHeader).pipe(
		  tap((_) => {
			// tslint:disable-next-line:no-console
			// console.info(_);
		  })
		);
	  }


  // Firebase Google Sign-in
  async signInWithGoogle(registerForm?: {
    rut: string;
    birthDate: { day: number, month: number, year: number };
    referrerId: string | null;
    acceptTerms: boolean;
  }): Promise<User> {
    try {
      if (this.partialUser) {
        if (registerForm) {
          return await this.setInitialUserConfigPartial(this.partialUser, registerForm);
        } else {
          if (this.currentUser) {
            return null;
          }
        }
      }
      const provider = new firebase.auth.GoogleAuthProvider();
      if (registerForm) {
        provider.setCustomParameters({
          prompt: 'consent'
        })
        provider.addScope('https://www.googleapis.com/auth/user.birthday.read');
        provider.addScope('https://www.googleapis.com/auth/user.phonenumbers.read');
      }
      const userCredential = await this.oAuthProvider(provider);
      const userToken = await userCredential.user.getIdToken(true);
      const response = await this.getProfileDataFromUser(JSON.stringify(userCredential));
      // TODO: move to unique form and indicate if birthday exists or not
      if (registerForm) {
        if (response.birthdays?.length > 0) {
          if (response.birthdays[0].date) {
            registerForm.birthDate = response.birthdays[0].date;
          }
        }
      } else {
        const userRegistrationConsistency = await this.cloudService.userRegistrationConsistency(userToken);
        if (userRegistrationConsistency.redirect.includes('registrar')) {
          this.router.navigate(['registrar']);
          this.swalService.swalWarning('Tu usuario no existe', 'Por favor, regístrate en esta sección para poder ingresar a Neat')
          return null;
        }
      }
      await this.setExtraConfig(userCredential);
      // tslint:disable-next-line: no-non-null-assertion
      if ((userCredential!.additionalUserInfo.isNewUser || !(await this.hasRut(userCredential)))) {
        // Register user
        if (registerForm) {
          return await this.setInitialUserConfig(userCredential, registerForm);
        } else {
          this.partialUser = userCredential;
          this.router.navigate(['registrar/registrogoogle']);
          return null;
        }
      } else {
        // Go to dashboard
        return await this.getInitialUserConfig(userCredential);
      }

    } catch (err) {
      this.errorService.recordError(err, 'user.service.ts',
        'this.oAuthProvider()', 'Error al iniciar sesión con google');
      return err;
    }
  }

  async getInitialUserConfig(userCredential: firebase.auth.UserCredential) {
    const trace = this.firePerformance.trace$('user from getInitialUserConfig()').subscribe();
    try {
      const getUserDoc = await this.getUserfromUserDoc(userCredential.user.uid)
        .catch((err) => {
          trace.unsubscribe();
          this.errorService.recordError(err, 'user.service.ts',
            'getUserfromUserDoc()', 'Error al obtener ususario desde doc data');
          throw err;
        });
      this.swalService.swalToastGeneral('¡Bienvenido de vuelta!', 'success');
      this.currentUser = getUserDoc;
      this.intercomInit(userCredential);
      this.currentUser.extend_with(userCredential.user, userCredential.additionalUserInfo);
      await this.fireAnalitycs.logEvent('login', { method: 'Google' });
      trace.unsubscribe();
      return this.currentUser;
    } catch (err) {
      trace.unsubscribe();
      this.errorService.recordError(err, 'user.service.ts',
        'this.getInitialUserConfig()', 'Error al registrarse con Google');
    }

  }

  intercomInit(userCredential: firebase.auth.UserCredential) {
    this.intercomService.updateIntercomUser(
      this.currentUser,
      Number(userCredential.user.metadata.creationTime) / 1000,
      Number(userCredential.user.metadata.creationTime) / 1000
    );
  }

  partialIntercomInit(userCredential: firebase.auth.UserCredential, newUser: User) {
    this.intercomService.updateIntercomUser(
      newUser,
      Number(userCredential.user.metadata.creationTime) / 1000,
      Number(userCredential.user.metadata.creationTime) / 1000
    );
  }

  async setExtraConfig(userCredential: firebase.auth.UserCredential) {
    const trace = this.firePerformance.trace$('user from setExtraConfig()').subscribe();
    await this.fireAnalitycs.setUserId(userCredential.user.uid);
    await this.fireAnalitycs.setUserProperties({
      name: userCredential.user.displayName,
      email: userCredential.user.email
    });
    trace.unsubscribe();
    this.errorService.setUser(userCredential.user);
  }

  async setInitialUserConfig(userCredential: firebase.auth.UserCredential, registerForm?: {
    rut: string;
    birthDate: { day: number, month: number, year: number };
    referrerId: string | null;
    acceptTerms: boolean;
  }) {
    const trace = this.firePerformance.trace$('user from setInitialUserConfig()').subscribe();
    try {
      const userToken = await userCredential.user.getIdToken(true);
      this.addIdToken(userToken);
      this.currentUser = new User().from_authProvider(AuthProvider.google, userCredential, registerForm);
      await Promise.all([this.createUserDocument(trace, 'registerGoogle'), this.storageNewBucket(trace, this.currentUser), this.fireAnalitycs.logEvent('sign_up', { method: 'Google' })]);
      this.intercomInit(userCredential);
      try {
        this.setInitialHeapConfig(userCredential.user.uid, userCredential.user.email);
      } catch (e) {
        this.errorService.recordError(e, 'user.service.ts',
          `heap.addUserProperties()', 'Error al asignar heap property to ${userCredential.user.email} in setInitialUserConfig`);
      }
      this.currentUser.extend_with(userCredential.user, userCredential.additionalUserInfo);
      await this.fireAnalitycs.logEvent('sign_up', { method: 'Google' });
      await this.waitForUserDocCreation(this.currentUser.id);
      trace.unsubscribe();
      return this.currentUser;
    } catch (err) {
      console.error(err);
      trace.unsubscribe();
      this.errorService.recordError(err, 'user.service.ts',
        'setInitialUserConfig', 'Error en creación de usuario (registerGoogle)');
      throw err;
    }
  }

  async setInitialUserConfigPartial(partialUserCredential: firebase.auth.UserCredential, registerForm?: {
    rut: string;
    birthDate: { day: number, month: number, year: number };
    referrerId: string | null;
    acceptTerms: boolean;
  }) {
    const trace = this.firePerformance.trace$('user from setInitialUserConfigPartial()').subscribe();
    try {
      const partialNewUser: User = new User().from_authProvider(AuthProvider.google, partialUserCredential, registerForm);
      await Promise.all([this.createGoogleUserDocument(partialNewUser), this.storageNewBucket(trace, partialNewUser), this.fireAnalitycs.logEvent('sign_up', { method: 'Google' })]);
      this.partialIntercomInit(partialUserCredential, partialNewUser);
      partialNewUser.extend_with(partialUserCredential.user, partialUserCredential.additionalUserInfo);
      await this.fireAnalitycs.logEvent('sign_up', { method: 'Google' });
      await this.waitForUserDocCreation(partialNewUser.id);
      trace.unsubscribe();
      return partialNewUser;
    } catch (err) {
      trace.unsubscribe();
      this.errorService.recordError(err, 'user.service.ts',
        'setInitialUserConfig', 'Error en creación de usuario (registerGoogle)');
      throw err;
    }
  }

  async createGoogleUserDocument(partialNewUser: User): Promise<void> {
    return await this.db.firestoreNewDoc('users', Object.assign({}, partialNewUser), partialNewUser.id)
      .catch((err) => {
        this.errorService.recordError(err, 'user.service.ts',
          'this.db.firestoreNewDoc', 'Error en creación de user doc (registerGoogle)');
      });
  }

  /**
   * Inicio de sesión mediante "EmailAndPassword", retorna una promesa de tipo User
   * @param value
   * Debe ser un objeto que contenga la propiedad email y password, ambos string
   */
  async signInWithEmail(value: { email: string; password: string }): Promise<User> {
    try {
      await this.fireAuth.auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .catch(err => {
          this.errorService.recordError(err, 'user.service.ts',
            'this.fireAuth.auth.setPersistence()', 'Error al setear persistencia de usuario en inicio de sesión');
          throw err;
        });
      const userCredential = await this.fireAuth.auth.signInWithEmailAndPassword(value.email, value.password)
        .catch(err => {
          this.errorService.recordError(err, 'user.service.ts',
            'signInWithEmailAndPassword()', 'Error al iniciar sesión');
          throw err;
        });
      const userToken = await userCredential.user.getIdToken(true);
      this.addIdToken(userToken);
      this.fireAnalitycs.logEvent('login', { method: 'Email' });
      this.fireAnalitycs.setUserId(userCredential.user.uid);
      this.errorService.setUser(userCredential.user);
      const userDoc = await this.getUserfromUserDoc(userCredential.user.uid)
        .catch(err => {
          this.errorService.recordError(err, 'user.service.ts',
            'this.getUserDoc()', 'Error al obtener userType en inicio de sesión');
          throw err;
      });
      if (userDoc?.userType !== UserType.admin) {
        await this.logOut();
        throw {code: 'admin-user'};
      }
      this.currentUser = userDoc;
      this.intercomService.updateIntercomUser(
        this.currentUser,
        Number(userCredential.user.metadata.creationTime) / 1000,
        Number(userCredential.user.metadata.lastSignInTime) / 1000
      );
      this.currentUser.extend_with(userCredential.user, userCredential.additionalUserInfo);
      return this.currentUser;
    } catch (err) {
      this.errorService.recordError(err, 'user.service.ts',
        'signInWithEmail()', 'Error al iniciar sesión');
      let msg = 'Error al iniciar sesión. ';
      msg += this.errorService.getErrorMessage(err.code);
      throw msg;
    }
  }

  async emailVerification(): Promise<void> {
    return await firebase.auth().currentUser.sendEmailVerification(this.actionCodeSettings)
      .catch(err => {
        this.errorService.recordError(err, 'user.service.ts',
          'this.fireAuth.auth.currentUser.sendEmailVerification()', 'Error al enviar email de notificación por Firebase');
        let msg = 'Error en verificación de correo. ';
        msg += this.errorService.getErrorMessage(err.code);
        throw msg;
      });
  }

  async rutEnroller(rut: string) {
    const trace = this.firePerformance.trace$('user from rutEnroller()').subscribe();
    const cfResponse: { status: boolean, message: string } = await this.cloudService.rutEnroller(rut)
      .catch(err => {
        trace.unsubscribe();
        this.errorService.recordError(err, 'google-register.component.ts',
          'this.cloudService.rutEnroller()', 'Error al enrolar rut con Cloud Funtion');
      });
    trace.unsubscribe();
    return cfResponse;
  }

  // Not sure if these can be use with async await pattern
  getUserBrowserCredentials(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      try {
        if ((window as any).PasswordCredential || (window as any).FederatedCredential) {
          (navigator as any).credentials.get({
            password: true,
            unmediated: false,
            federated: {
              providers: [
                'https://account.google.com',
                // 'https://www.facebook.com'
              ]
            }
          }).then((cred) => {
            if (cred) {
              resolve(cred);
            } else {
              resolve();
            }
          })
            .catch(err => {
              this.errorService.recordError(err, 'user.service.ts',
                'getUserBrowserCredentials()', 'Error al obtener credenciales en then');
              reject();
            });
        } else {
          // public-key credentials not supported
          resolve();
        }
      } catch (err) {
        this.errorService.recordError(err, 'user.service.ts',
          'getUserBrowserCredentials()', 'Error al obtener credenciales');
        reject();
      }
    });
  }
  // Not sure if these can be use with async await pattern
  userAutoLoginWithCreds(cred: any) {
    return new Promise<boolean>(async (resolve, reject) => {
      if (cred) {
        switch (cred.type) {
          case 'password':
            try {
              this.signInWithEmail({ email: cred.id, password: cred.password })
                .then(() => resolve(true))
                .catch(err => {
                  reject(err);
                });
            } catch (err) {
              reject(err.message);
            }
            break;
          case 'federated':
            switch (cred.provider) {
              case 'https://accounts.google.com':
              // run google identity authentication flow
              // case 'https://www.facebook.com':
              //   // run facebook identity authentication flow
              //   break;
            }
            break;
        }
      } else {
        resolve(false);
      }
    });
  }

  async passwordReset(email: string): Promise<void> {
    await this.fireAuth.auth.sendPasswordResetEmail(email, this.actionCodeSettings)
      .catch(err => {
        this.errorService.recordError(err, 'user.service.ts',
          'this.fireAuth.auth.sendPasswordResetEmail()', 'Error al resetear la contraseña por Firebase');
        let msg = 'Error al restablecer la contraseña. ';
        msg += this.errorService.getErrorMessage(err.code);
        throw msg;
      });
    this.fireAnalitycs.logEvent('User password reset');
  }

  async requestLoginWithLink(email: string) {
    const actionCodeSettings = {
      url: `${AppGlobals.neatUrl}`,
      handleCodeInApp: true
    };
    await firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings).then(() => {
        window.localStorage.setItem('emailForSignIn', email);
        heap.track('requestLogInLink', {email: email, date: new Date()});
        this.fireAnalitycs.logEvent(`User request login without pass, using link sended to his email: ${email}`);
      }).catch((error) => {
        this.errorService.recordError(error, 'user.service.ts',
        'loginWithLinkRequest()', `Error al enviar link para autenticación con vinculo al mail: ${email}, errorCode: ${error.code}, errorMsg: ${error.message} `);
        throw 'Error al enviar link para autenticación con vínculo al mail';
    });
  }

  async loginWithLink(email: string) {
    await firebase.auth().signInWithEmailLink(email, window.location.href).then((result) => {
      // Clear email from storage.
      window.localStorage.removeItem('emailForSignIn');
      // You can access the new user via result.user
      // Additional user info profile not available via:
      // result.additionalUserInfo.profile == null
      // You can check if the user is new or existing:
      if (!result.additionalUserInfo.isNewUser) {
        heap.track('SignInViaLink', {email: email, date: new Date()});
        this.fireAnalitycs.logEvent(`User request login without pass, using link sended to his email: ${email}`);
      } else {
        throw 'No tienes una cuenta creada en Neat';
      }
    })
    .catch((error) => {
      this.errorService.recordError(error, 'user.service.ts',
        'loginWithLink()', `Error al iniciar sesión con link - email: ${email}`);
        throw 'Error al iniciar sesión con enlace';
    });
  }

  async registerWithEmail(registerFormValues: RegisterForm): Promise<User> {
    const trace = this.firePerformance.trace$('user from registerWithEmail()').subscribe();
    try {
      const userCredential = await this.fireAuth.auth.createUserWithEmailAndPassword(registerFormValues.email, registerFormValues.password);
      this.errorService.setUser(userCredential.user);
      this.currentUser = new User().from_authProvider(AuthProvider.firebase, userCredential, registerFormValues);
      this.fireAnalitycs.logEvent('sign_up', { method: 'Email' });
      this.fireAnalitycs.setUserId(userCredential.user.uid);
      this.fireAnalitycs.setUserProperties({
        name: this.currentUser.displayName,
        email: userCredential.user.email
      });
      this.intercomService.updateIntercomUser(
        this.currentUser,
        Number(userCredential.user.metadata.creationTime) / 1000,
        Number(userCredential.user.metadata.creationTime) / 1000
      );
      await Promise.all([this.createUserDocument(trace, 'registerWithEmail'), this.updateProfile(), this.storageNewBucket(trace, this.currentUser)]);
      this.currentUser = this.currentUser.extend_with(userCredential.user, userCredential.additionalUserInfo);
      await this.waitForUserDocCreation(this.currentUser.id);
      trace.unsubscribe();
      this.userSaved = this.currentUser;
      return this.currentUser;
    } catch (err) {
      trace.unsubscribe();
      this.errorService.recordError(err, 'user.service.ts',
        'registerWithEmail()', 'Error al registrar usuario ' + JSON.stringify(this.currentUser));
      let msg = 'Error al registrarse. ';
      msg += this.errorService.getErrorMessage(err.code);
      throw msg;
    }
  }

  async createUserDocument(trace: any, provider: string): Promise<void> {
    return await this.db.firestoreNewDoc('users', Object.assign({}, this.currentUser), this.currentUser.id)
      .catch(err => {
        this.errorService.recordError(err, 'user.service.ts',
          'this.db.firestoreNewDoc()', 'Error en creación de doc para user' + this.currentUser.id + ` ${provider}`);
      })
      .finally(() => trace.unsubscribe());
  }

  async updateProfile(): Promise<void> {
    return await this.fireAuth.auth.currentUser.updateProfile({ displayName: this.currentUser.displayName });
  }

  async storageNewBucket(trace: any, user: User): Promise<void> {
    return await this.db.storageNewBucket('/users/' + user.id + '/createBucket')
      .catch(err => {
        this.errorService.recordError(err, 'user.service.ts',
          'this.db.storageNewBucket()', 'Error crear bucket user para ' + user.id);
      })
      .finally(() => trace.unsubscribe());
  }

  async waitForUserDocCreation(id: string) {
    const trace = this.firePerformance.trace$('waitForUserDocCreation').subscribe();
    await sleep(1000);
    let userDocData = (await this.db.firestoreGetData('users', id)).data();
    while (!userDocData.creationDone) {
      await sleep(350);
      userDocData = (await this.db.firestoreGetData('users', id)).data();
    }
    trace.unsubscribe();
  }

  isLoggedIn() {
    // return this.afAuth.authState.pipe(first()).toPromise();
    return this.fireAuth.auth.currentUser;
  }

  // Are we using the seaction?

  cargarStorage() {
    if (localStorage.getItem('id')) {
      this.user = JSON.parse(localStorage.getItem('user'));
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
      this.userId = localStorage.getItem('id');
      this.displayName = localStorage.getItem('userName');
      this.userRut = localStorage.getItem('userRut');
      this.displayName = localStorage.getItem('userName');
      this.userPhotoUrl = localStorage.getItem('userPhotoUrl');
    } else {
      this.user = null;
      this.userId = '';
      this.userInfo = null;
      this.displayName = '';
      this.userRut = '';
      this.displayName = '';
      this.userPhotoUrl = '';
    }
  }

  guardarStorage(id: string, user: User, userInfo: boolean) {
    localStorage.setItem('id', id);
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
    this.user = user;
    this.userId = id;
    this.userInfo = userInfo;
  }

  borrarStorage() {
    this.user = null;
    this.userId = '';
    this.displayName = '';
    this.userRut = '';
    this.userPhotoUrl = '';
    this.userInfo = null;

    localStorage.removeItem('userInfo');
    localStorage.removeItem('id');
    localStorage.removeItem('user');
    localStorage.removeItem('userName');
    localStorage.removeItem('userRut');
    localStorage.removeItem('userPhotoUrl');
  }
  userNameStorageSave(userName: string) {
    this.displayName = userName;
    localStorage.setItem('userName', userName);
  }
  rutStorageSave(rut: string = '') {
    this.userRut = rut;
    localStorage.setItem('userRut', rut);
  }
  checkRutStorage(): boolean {
    if (localStorage.getItem('userRut')) {
      return true;
    } else {
      return false;
    }
  }
  photoUrlStorageSave(userPhotoUrl: string) {
    this.userPhotoUrl = userPhotoUrl;
    localStorage.setItem('userPhotoUrl', userPhotoUrl);
  }

  setInitialHeapConfig(userID: string, userEmail: string) {
    heap.identify(userID);
    heap.addUserProperties({ 'email': userEmail });
  }

  setHeapConfig(user: User) {
    heap.identify(user.id);
    heap.addUserProperties({
      'email': user.email,
      'referred': user.referrerId ? true : false,
      'category': user.category,
      'comesFrom': user.comesFrom,
      'continuousMonthsPaid': user.continuousMonthsPaid,
      'emailVerified': user.emailVerified,
      'referredRegistered': user.referredRegistered ? user.referredRegistered.length : 0,
      'referredWhoPayed': user.referredWhoPayed ? user.referredWhoPayed.length : 0
    });
  }

  deleteUserRequest(tokenId: string): Observable<any> {
    let endpoint: string;
    endpoint = AppGlobals.deleteUser;
    if (isDevMode()) {
      endpoint = AppGlobals.deleteUserBeta;
    }
    const httpHeader = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${tokenId}`
      }),
      observe: "response" as 'body',
      responseType: 'text' as 'json'
    };
    return this.http.post<any>(endpoint, {}, httpHeader).pipe(
      tap((_) => {
        // tslint:disable-next-line:no-console
        // console.info(_);
      }),
      catchError(this.handleError<any>('deleteUserRequest'))
    );
  }

  private handleError<T>(operation: string, result?: T) {
    return (error: any): Observable<T> => {
      this.errorService.recordError(error, 'user.service.ts',
        operation, 'Error al procesar comunicación https');
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  async getDepositeeInfo(user: User) {
    const depositeeInfoAFSCollection = this.afs.collection("depositeeInfo",
      (ref) => ref.where('id', '==', user.id).where('email', '==', user.email)
    );
    depositeeInfoAFSCollection.valueChanges().subscribe(depositeeInfoDoc => {
      this.depositeeInfo$.next(depositeeInfoDoc);
    });
    depositeeInfoAFSCollection.get().subscribe((querySnapshot) => {
      querySnapshot.forEach((document) => {
        document.ref.collection("associatedPayer").onSnapshot((querySnapshot) => {
          this.associatedPayer$.next(querySnapshot.docs);
        });
      });
    });

    depositeeInfoAFSCollection.get().subscribe((querySnapshot) => {
      querySnapshot.forEach((document) => {
        document.ref.collection("associatedPayerEvent").onSnapshot((querySnapshot) => {
          this.associatedPayerEvent$.next(querySnapshot.docs);
        });
      });
    });

    depositeeInfoAFSCollection.get().subscribe((querySnapshot) => {
      querySnapshot.forEach((document) => {
        document.ref.collection("subscriptions").onSnapshot((querySnapshot) => {
           querySnapshot.docs.forEach(res => {
              this.subscriptions$.next(res.data());
            });
        });
      });
    });
  }


}

interface RegisterForm {
  email: string;
  password: string;
  name: string;
  lastNames: string;
  rut: string;
  birthDate: { day: number, month: number, year: number };
  referrerId: string | null;
  acceptTerms: boolean;
}
