import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NeatTextPipe } from './neat-text/neat-text.pipe';
import { RoutesReplacePipe } from './routes-replace/routes-replace.pipe';
import { ReplaceTranslatePipe } from './replace-translate/replace-translate.pipe';
import { ImagePipe } from './image/image.pipe';
import { ClpCurrencyPipe } from './clp-currency/clp-currency.pipe';
import { UfFormatPipe } from './uf-format/uf-format.pipe';
import { WrongFieldsNamesPipe } from './wrong-fields-names/wrong-fields-names.pipe';
import { ReplacePipe } from './replace/replace.pipe';
import { ToPrecisionPipe } from './to-precision/to-precision.pipe';
import { WithLoadingPipe } from './with-loading/with-loading.pipe';
import { ToFixedPipe } from './to-fixed/to-fixed.pipe';
import { MdToHtmlPipe } from './md-to-html/md-to-html.pipe';
import { ReadingTimePipe } from './reading-time/reading-time.pipe';
import { ContentResumePipe } from './content-resume/content-resume.pipe';
import { LocalDatePipe } from './local-date/local-date.pipe';
import { TruncateTogglePipe } from './truncate-toggle/truncate-toggle.pipe';
import { FormatRutPipe } from './format-rut/format-rut.pipe';
import { ReplaceAllPipe } from './replace-all/replace-all.pipe';
import { StylesPipe } from './styles.pipe';

@NgModule({
  declarations: [
    ReplaceTranslatePipe,
    RoutesReplacePipe,
    NeatTextPipe,
    ImagePipe,
    WrongFieldsNamesPipe,
    ClpCurrencyPipe,
    UfFormatPipe,
    ReplacePipe,
    ToPrecisionPipe,
    WithLoadingPipe,
    ToFixedPipe,
    MdToHtmlPipe,
    ReadingTimePipe,
    ContentResumePipe,
    LocalDatePipe,
    TruncateTogglePipe,
    FormatRutPipe,
    ReplaceAllPipe,
    StylesPipe,
  ],
  imports: [CommonModule],
  exports: [
    ReplaceTranslatePipe,
    RoutesReplacePipe,
    NeatTextPipe,
    ImagePipe,
    WrongFieldsNamesPipe,
    ClpCurrencyPipe,
    UfFormatPipe,
    ReplacePipe,
    ToPrecisionPipe,
    WithLoadingPipe,
    ToFixedPipe,
    MdToHtmlPipe,
    ReadingTimePipe,
    ContentResumePipe,
    LocalDatePipe,
    TruncateTogglePipe,
    FormatRutPipe,
    ReplaceAllPipe,
    StylesPipe
  ]
})
export class PipesModule {}
