<section>
    <div id="notfound">
		<div class="notfound">
			<div class="notfound-404">
				<h1>4<span></span>4</h1>
			</div>
			<h2>Oops! Página no encontrada</h2>
			<p>Lo sentimos pero la página que estás buscando no existe, ha sido removida o el nombre ha sido modificado.</p>
			<a [routerLink]="[ '/dashboard' ]">Volver al inicio</a>
		</div>
	</div>
</section>