import { Injectable, isDevMode } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookiesService {

  constructor() { }

  setCookie(cname:string, cvalue: string, dname: string, exdays: number) {
    let d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    let domain = `domain=${dname}`;
    if (isDevMode()) {
      document.cookie = `${cname}=${cvalue};${expires};path=/`;
    } else {
      document.cookie = `${cname}=${cvalue};${domain};${expires};path=/`;
    }
  }

  getCookie(cname:string): string {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  deleteCookie(cname: string, dname: string): void {
    let domain = `domain=${dname}`;
    if (isDevMode()) {
      document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    } else {
      document.cookie = `${cname}=; ${domain} ; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }
  }

}
