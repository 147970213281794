import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PasswordResetComponent } from './../../../landing/shared/password-reset/password-reset.component';
import { DeleteUserModalComponent } from 'app/dashboard/shared/delete-user-modal/delete-user-modal.component';
import { LoginWithoutPasswordComponent } from 'app/landing/shared/login-without-password/login-without-password.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<any>) {}


  openPasswordResetDialog() {
    this.dialog.open(PasswordResetComponent, {
      maxWidth: '95vw',
      width: '450px'
    });
  }

  openLoginWithLink() {
    this.dialog.open(LoginWithoutPasswordComponent, {
      maxWidth: '95vw',
      width: '450px'
    }); 
  }

  openDeleteUserModal(userId: string) {
    this.dialog.open(DeleteUserModalComponent, {
      maxWidth: '100%',
      minWidth: '30%',
      maxHeight: '100vh',
      width: '580px',
      hasBackdrop: true,
      panelClass: 'neat-payment-summary-modal',
      data: {
        userId
      },
      disableClose: true
    });
  }

  closeAll() {
    this.dialog.closeAll();
  }
}
