import { EmailHandlerModule } from './email-handler/email-handler.module';
import { environment } from './../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';

import { FullCalendarModule } from '@fullcalendar/angular'; // for FullCalendar!
import { CarouselModule } from 'ngx-owl-carousel-o';

import { HttpClientModule } from '@angular/common/http';

// Device detector
import { DeviceDetectorModule } from 'ngx-device-detector';

import { AngularMaterialModule } from './shared/angular-material/angular-material.module';
import { AppRoutingModule } from './app-routing.module';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFirePerformanceModule } from '@angular/fire/performance';
import { AngularFireRemoteConfigModule, DEFAULTS, SETTINGS } from '@angular/fire/remote-config';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { LoginGuard } from './guards/login/login.guard';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Integrations as ApmIntegrations } from '@sentry/apm';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { AppGlobals } from '@shared/constants';


const requestOptions = {
  method: 'GET',
  headers: {
    'x-api-key': environment.versionApiKey,
    'Content-type': 'application/json'
  }
};

const getVersionUrl = AppGlobals.versionService;

fetch(getVersionUrl, requestOptions)
  .then(response => response.json())
  .then(result => {
    const localVersion = localStorage.getItem('last-version');
    const remoteVersion = result.shaVersion;

    if (!localVersion || localVersion == 'undefined') {
      localStorage.setItem('last-version', 'AAXXZZ');
      window.location.reload();
      return;
    }

    if (localVersion != remoteVersion) {
      localStorage.setItem('last-version', remoteVersion);
      window.location.reload();
      return;
    }
  })
  .catch(error => console.log('error', error));

Sentry.init(Object.assign(
  environment.sentry,
  { integrations: [new ApmIntegrations.Tracing()] })
);
@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() { }
  handleError(error) {
    const eventId = Sentry.captureException(error.originalError || error);
    if (environment.sentry.active) {
      Sentry.showReportDialog({ eventId });
    }
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features
    AngularFireStorageModule, // imports firebase/storage, only needed for storage features
    AngularFireMessagingModule, // imports firebase/messaging, only needed for messaging features
    AngularFireFunctionsModule,
    AngularFirePerformanceModule,
    AngularFireAnalyticsModule,
    AngularFireRemoteConfigModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    FullCalendarModule, // for FullCalendar!
    BrowserAnimationsModule,
    CarouselModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    DeviceDetectorModule.forRoot(),
    EmailHandlerModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: true }),
    RecaptchaV3Module,
  ],
  exports: [],
  providers: [
    // { provide: ErrorHandler, useClass: SentryErrorHandler },
    {
      provide: DEFAULTS, useValue: {
        payment_provider_status: true,
        payment_problem_text: 'Disculpa, el servicio de pagos se encuentra suspendido temporalmente',
        servipag_url_fixer_enable: false
      }
    },
    {
      provide: SETTINGS,
      // 3 hrs of fetch interval and 1 min fetch Timeout
      useFactory: () => true ? { minimumFetchIntervalMillis: 900000, fetchTimeoutMillis: 60000 } : {}
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: '6LcqerUUAAAAAP4qyBEPTqYEjpV6kS6sfouZr5Sx'
    },
    LoginGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
