import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class EmailHandlerService {
  actionHandlerParams: {
    mode: string;
    actionCode: string;
    continueUrl?: string;
    lang?: string | 'en';
  };
  constructor(private router: Router,
              private fireAuth: AngularFireAuth, ) {
    this.fireAuth.auth.languageCode = 'es';
    this.fireAuth.auth.useDeviceLanguage();
    this.actionHandlerParams = { mode: '', actionCode: '' };
  }

  handleVerifyEmail(actionCode: string, lang?: string): Promise<void> {
    return this.fireAuth.auth.applyActionCode(actionCode);
  }

  handleResetPassword(actionCode: string, lang?: string): Promise<string> {
    return this.fireAuth.auth.verifyPasswordResetCode(actionCode);
  }
}
